import React, { useEffect, useState } from 'react';
import CommitMetricsDisplay from '@/components/CommitMetricsDisplay';
import { useSession } from '@/hooks/useSession';
import { BACKEND_URL } from '@/lib/constants'
import { useParams } from 'react-router-dom';

const CommitMetrics: React.FC = () => {
  const [commitMetrics, setCommitMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const { accessToken } = useSession();
  const { org, repo } = useParams();
  const repoName = `${org}/${repo}`;

  useEffect(() => {
    if (accessToken) {
      (async () => {
        try {
            const response = await fetch(`${BACKEND_URL}/backend/api/commit_metrics`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({ repo_names: [repoName] }),
            });
      
            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }
      
            const data = await response.json();
            setCommitMetrics(data.commit_metrics);
          } catch (err: any) {
            setError(err.message);
          } finally {
            setLoading(false);
          }
      })()
    }
  }, [accessToken])

  if (loading) return <div className="text-white text-center h-screen flex items-center justify-center">Loading data...</div>;
  if (!org || !repo) return <div className="text-white text-center h-screen flex items-center justify-center">Make sure the url is in the format /commit_metrics/org-name/repo-name</div>;
  if (error) return <div className="text-white text-center h-screen flex items-center justify-center">Error: {error}</div>;

  return <CommitMetricsDisplay commitMetrics={commitMetrics} />;
};

export default CommitMetrics;
