import { useForceDarkMode } from "@/hooks/useForceDarkMode";
import { useGitHubCallback, useSession } from "@/hooks/useSession";
import { Provider } from "jotai";
import { Button } from "./ui/button";
import { FaGithub } from "react-icons/fa";
import { ThemeProvider } from "./theme-provider";
import { PostHogProvider } from "posthog-js/react";
import { useHydrateAtoms } from "jotai/utils"
import { accessTokenAtom, userAtom } from "@/hooks/useSession";
import { useMatch } from "react-router-dom";
import { messagesIdState } from "@/state/atoms";
import SweepingGif from "@/../public/sweeping.gif";


const HydrateAtoms = ({ initialValues, children }: { initialValues: any[], children: React.ReactNode }) => {
  useHydrateAtoms(initialValues);
  return children;
}

export default function AppWrapper<T extends React.ComponentType<any>>(Component: T) {
  const WrappedComponent = (props: React.ComponentProps<T>) => {
    const match = useMatch("/c/:defaultMessagesId");
    const defaultMessagesId = match?.params?.defaultMessagesId || "";

    const { signIn } = useGitHubCallback();
    const { accessToken, user } = useSession()

    useForceDarkMode()

    if (window.location.pathname.includes("/api/auth/callback/github")) {
      return (
        <main className="flex h-screen items-center justify-center p-12">
          <img src={SweepingGif} className="w-32 h-32 rounded-full object-cover shadow-sm"/>
        </main>
      )
    }

    if (!accessToken) {
      return (
        <main className="flex h-screen items-center justify-center p-12">
          <Button
            id="sign-in-github-button"
            onClick={signIn}
            variant="secondary"
          >
            <FaGithub className="inline-block mr-2" style={{ marginTop: -2 }} />
            Sign in with GitHub
          </Button>
        </main>
      )
    }

    const component = (
      <ThemeProvider>
        <Provider>
          <HydrateAtoms initialValues={[
            [accessTokenAtom, accessToken],
            [userAtom, user],
            [messagesIdState, defaultMessagesId]
          ]}>
            <Component {...props} />
          </HydrateAtoms>
        </Provider>
      </ThemeProvider>
    )

    return (
      <PostHogProvider>
        {component}
      </PostHogProvider>
    )
  }
  WrappedComponent.displayName = `AppWrapper(${Component.displayName || Component.name})`
  return WrappedComponent
}
