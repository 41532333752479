import { CodeSuggestion, Message, PullRequest, StatefulCodeSuggestion } from "@/lib/types";
import { branchState, commitMessageState, commitToPRState, featureBranchState, isCreatingPullRequestState, messagesIdState, messagesState, pullRequestBodyState, pullRequestState, pullRequestTitleState, repoNameState, scrollToBottomState, } from "@/state/atoms";
import { useSession } from "@/hooks/useSession";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { FaArrowLeft, FaChevronDown, FaChevronUp, FaTimes } from "react-icons/fa";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { toast } from "./ui/use-toast";
import { Label } from "./ui/label";
import { getDiffMetadata, truncate } from "@/lib/strUtils";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "./ui/alert-dialog";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "./ui/collapsible";
import CopyButton from "./CopyButton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { FaCodeMerge, FaCodePullRequest } from "react-icons/fa6";
import SuggestedChangeDisplay from "./SuggestedChangeDisplay";
import { Textarea } from "./ui/textarea";
import { withLoading } from "@/lib/contextManagers";
import PulsingLoader from "./shared/PulsingLoader";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { GITHUB_BASE_URL } from "@/lib/constants";

const CustomSelect = ({
  value,
  onValueChange,
  options,
}: {
  value: any
  onValueChange: any
  options: any
}) => {
  const [open, setOpen] = useState(false)
  const triggerRef = useRef(null)

  return (
    <div className="relative inline-block p-0">
      <div
        ref={triggerRef}
        onClick={() => {
          setOpen(!open)
        }}
        className="flex items-center justify-between px-0 py-0 text-sm rounded-md cursor-pointer hover:text-accent-foreground data-[state=active]:bg-accent data-[state=active]:text-accent-foreground"
      >
        <span>
          Merge into PR #{JSON.parse(value).number} -{' '}
          {truncate(JSON.parse(value).title, 15)}
        </span>
        {open ? (
          <FaChevronUp className="ml-2 h-4 w-4" />
        ) : (
          <FaChevronDown className="ml-2 h-4 w-4" />
        )}
      </div>
      {open && (
        <div className="absolute z-10 w-full mt-1 bg-accent rounded-md shadow-lg">
          {options.map((pr: any) => (
            <div
              key={pr.number}
              className="px-3 py-2 border cursor-pointer hover:bg-background hover:text-accent-foreground"
              onClick={() => {
                onValueChange(JSON.stringify(pr))
                setOpen(false)
              }}
            >
              #{pr.number} - {truncate(pr.title, 15)}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const ChangesMadeDisplay = ({
  index,
  userMentionedPullRequest,
  setUserMentionedPullRequest_,
  userMentionedPullRequests,
  setFinishedCreatingPullRequest,
  authorizedFetch,
}: {
  index: number
  userMentionedPullRequest: PullRequest | undefined
  setUserMentionedPullRequest_: Dispatch<SetStateAction<PullRequest | undefined>>
  userMentionedPullRequests: PullRequest[]
  setFinishedCreatingPullRequest: (finishedCreatingPullRequest: boolean) => void
  authorizedFetch: (url: string, options: any) => Promise<Response | undefined>
}) => {
  const [pullRequestTitle, setPullRequestTitle] = useAtom(pullRequestTitleState)
  const [pullRequestBody, setPullRequestBody] = useAtom(pullRequestBodyState)
  const [commitMessage, setCommitMessage] = useAtom(commitMessageState)
  const [isCreatingPullRequest, setIsCreatingPullRequest] = useAtom(isCreatingPullRequestState)
  const [messages, setMessages] = useAtom(messagesState)
  const [commitToPR, setCommitToPR] = useAtom(commitToPRState)
  const setBranch = useSetAtom(branchState);
  const setFeatureBranch = useSetAtom(featureBranchState)
  const setPullRequest = useSetAtom(pullRequestState)
  const messagesId = useAtomValue(messagesIdState)
  const repoName = useAtomValue(repoNameState)
  const branch = useAtomValue(branchState)
  const setScrollToBottom = useSetAtom(scrollToBottomState)

  const message = messages[index]
  const { username } = useSession()
  const [appliedChangesCollapsed, setAppliedChangesCollapsed] = useState(false)
  const localAppliedChanges = message.annotations?.codeSuggestions || []
  const localFeatureBranch = message.annotations?.featureBranch
  
  
  const linesAdded = localAppliedChanges.reduce(
    (acc, suggestion) => acc + getDiffMetadata(suggestion).numLinesAdded,
    0
  )
  const linesRemoved = localAppliedChanges.reduce(
    (acc, suggestion) => acc + getDiffMetadata(suggestion).numLinesRemoved,
    0
  )
  return (
    localFeatureBranch &&
    localAppliedChanges && (
      <div className="my-2 mb-6 p-8 border rounded bg-zinc-900">
        <div className="flex items-start justify-between mb-4">
          <div>
            <h2 className="text-2xl font-bold mb-1">
              Changes Commited to New Branch
            </h2>
            <h4 className="text-zinc-400 font-mono">{localFeatureBranch}</h4>
            <h4 className="text-zinc-400">
              {localAppliedChanges.length} file
              {localAppliedChanges.length > 1 ? 's' : ''} changed
              {linesAdded > 0 && (
                <span className="text-green-500">&nbsp;+{linesAdded}</span>
              )}
              {linesRemoved > 0 && (
                <span className="text-red-500">&nbsp;-{linesRemoved}</span>
              )}
            </h4>
          </div>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant="secondary">
                <FaTimes />
                &nbsp;&nbsp;Reject All Changes
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to reject the changes? This can&apos;t
                  be undone.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction asChild>
                  <Button
                    variant="destructive"
                    onClick={() => {
                      setMessages((messages) =>
                        messages.map((message, index_) => {
                          if (index_ === index) {
                            return {
                              ...message,
                              annotations: {
                                ...message.annotations,
                                codeSuggestions: undefined,
                                featureBranch: undefined,
                              },
                            }
                          }
                          return message
                        })
                      )
                    }}
                    className="bg-red-600 hover:bg-red-700 text-white"
                  >
                    Yes, Reject All Changes
                  </Button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
        <Collapsible
          open={!appliedChangesCollapsed}
          onOpenChange={(open) => setAppliedChangesCollapsed(!open)}
        >
          <CollapsibleTrigger className="flex items-center justify-between w-full">
            <Button variant="secondary" className="w-full mt-4">
              <div className="flex flex-row items-baseline justify-between w-full">
                <code className="text-zinc-3000">
                  {truncate(localFeatureBranch, 50)}
                </code>
                <div className="flex flex-row items-center">
                  {appliedChangesCollapsed ? (
                    <FaChevronDown className="mr-2" />
                  ) : (
                    <FaChevronUp className="mr-2" />
                  )}
                  {appliedChangesCollapsed ? 'See Changes' : 'Hide Changes'}
                </div>
              </div>
            </Button>
          </CollapsibleTrigger>
          <CollapsibleContent className="mt-4">
            {localAppliedChanges.map((suggestion, suggestionIndex) => (
              <SuggestedChangeDisplay
                repoName={repoName}
                branch={localFeatureBranch}
                suggestion={suggestion}
                key={`${suggestionIndex}-${JSON.stringify(suggestion)}`}
                isAutoFixing={false}
                setSuggestedChange={(
                  suggestedChangeSetStateAction: SetStateAction<StatefulCodeSuggestion>
                ) => {
                  setMessages((messages) =>
                    messages.map((message, idx) => {
                      if (idx !== index) return message

                      const currentSuggestions =
                        message.annotations?.codeSuggestions || []
                      const newSuggestion =
                        typeof suggestedChangeSetStateAction === 'function'
                          ? suggestedChangeSetStateAction(
                              currentSuggestions[suggestionIndex]
                            )
                          : suggestedChangeSetStateAction

                      return {
                        ...message,
                        annotations: {
                          ...message.annotations,
                          // codeSuggestions: newSuggestions
                          codeSuggestions: [
                            ...currentSuggestions.slice(0, suggestionIndex),
                            newSuggestion,
                            ...currentSuggestions.slice(suggestionIndex + 1),
                          ],
                        },
                      }
                    })
                  )
                }}
                removeSuggestedChange={() => {
                  setMessages((messages) =>
                    messages.map((message, idx) => {
                      if (idx !== index) return message
                      return {
                        ...message,
                        annotations: {
                          ...message.annotations,
                          codeSuggestions:
                            message.annotations?.codeSuggestions?.filter(
                              (_, i) => i !== suggestionIndex
                            ),
                        },
                      }
                    })
                  )
                }}
              />
            ))}
          </CollapsibleContent>
        </Collapsible>
        <p className="mt-6">Check out changes locally:</p>
        <pre className="mt-2 mb-4 p-4 bg-zinc-700 border rounded-lg relative flex items-center">
          <code className="text-zinc-300 whitespace-pre-wrap">
            git fetch && git checkout {localFeatureBranch}
          </code>
          <CopyButton
            className="absolute my-auto right-2"
            value={`git fetch && git checkout ${localFeatureBranch}`}
          />
        </pre>
        <AlertDialog>
          <div className="flex items-center justify-end">
            <AlertDialogTrigger asChild>
              <Button id="create-pull-request-button-outer" variant="primary">
                <FaCodePullRequest className="mr-2" />
                Create Pull Request
              </Button>
            </AlertDialogTrigger>
          </div>
          <AlertDialogContent className="p-8">
            <AlertDialogHeader>
              <AlertDialogTitle>Apply Changes</AlertDialogTitle>
            </AlertDialogHeader>
            <Tabs
              className={`mt-2 ${isCreatingPullRequest ? 'opacity-50' : ''}`}
              value={commitToPR ? 'commit-to-pull' : 'create-pull'}
              onValueChange={(value) =>
                setCommitToPR(value == 'commit-to-pull')
              }
            >
              <TabsList className="mb-4">
                <TabsTrigger value="create-pull">
                  <FaCodePullRequest className="mr-2" /> Create New Pull Request
                </TabsTrigger>
                <TabsTrigger value="commit-to-pull">
                  {userMentionedPullRequests.length > 1 ? (
                    <CustomSelect
                      value={JSON.stringify(userMentionedPullRequest)}
                      onValueChange={(value: any) => {
                        const pullRequest = JSON.parse(value) as PullRequest
                        setUserMentionedPullRequest_(pullRequest)
                        setBranch(pullRequest.branch)
                      }}
                      options={userMentionedPullRequests}
                    />
                  ) : (
                    <>
                      <FaCodeMerge className="mr-2" />
                      Push to Branch
                    </>
                  )}
                </TabsTrigger>
              </TabsList>
              <TabsContent value="create-pull">
                <Input
                  value={pullRequestTitle || ''}
                  onChange={(e) => setPullRequestTitle(e.target.value)}
                  placeholder="Pull Request Title"
                  className="w-full mb-4 text-zinc-300"
                  disabled={pullRequestTitle == undefined}
                />
                <Textarea
                  value={pullRequestBody || ''}
                  onChange={(e) => setPullRequestBody(e.target.value)}
                  placeholder="Pull Request Body"
                  className="w-full mb-4 text-zinc-300"
                  disabled={pullRequestTitle == undefined}
                  rows={8}
                />
                <div className="flex grow items-center mb-4">
                  <Input
                    className="flex items-center"
                    value={branch || ''}
                    onChange={(e) => setBranch(e.target.value)}
                    placeholder="Base Branch"
                  />
                  <FaArrowLeft className="mx-4" />
                  <Input
                    className="flex items-center"
                    value={localFeatureBranch || ''}
                    onChange={(e) =>
                      setMessages(
                        messages.map((message, index) => {
                          if (index === messages.length - 1) {
                            return {
                              ...message,
                              annotations: {
                                ...message.annotations,
                                featureBranch: e.target.value,
                              },
                            }
                          }
                          return message
                        })
                      )
                    }
                    placeholder="Feature Branch"
                  />
                </div>
                <div className="flex justify-end my-2">
                  <AlertDialogCancel className="mr-4">Cancel</AlertDialogCancel>
                  <Button
                    id="create-pull-request-button-inner"
                    variant="primary"
                    onClick={async () => {
                      withLoading(
                        setIsCreatingPullRequest,
                        async () => {
                          const file_changes = localAppliedChanges.reduce(
                            (
                              acc: { [key: string]: string },
                              suggestion: CodeSuggestion
                            ) => {
                              acc[suggestion.filePath] = suggestion.newCode
                              return acc
                            },
                            {}
                          )
                          let response: Response | undefined
                          const createdBranch =
                            localFeatureBranch ||
                            'sweep-chat-patch-' +
                              new Date().toISOString().split('T')[0] // use ai for better branch name, title, and body later
                          response = await authorizedFetch(`/create_pull`, {
                            file_changes: file_changes,
                            base_branch: branch,
                            branch: createdBranch,
                            title: pullRequestTitle,
                            body:
                              pullRequestBody +
                              `\n\nSuggested changes from Sweep Chat by @${username}. Continue chatting at ${window.location.origin}/c/${messagesId}.`,
                          })
                          setFeatureBranch(createdBranch)
                          const data = await response!.json()
                          const {
                            pull_request: pullRequest,
                            new_branch: newBranch,
                          } = data
                          pullRequest.branch = newBranch
                          console.log('pullrequest', pullRequest)
                          setPullRequest(pullRequest)
                          // for commits, show a different message
                          const newMessages: Message[] = [
                            ...messages.slice(0, index),
                            {
                              ...message,
                              annotations: {
                                ...message.annotations,
                                codeSuggestions: undefined,
                                featureBranch: undefined,
                              },
                            },
                            ...messages.slice(index + 1),
                            {
                              content: `Pull request created: [https://${GITHUB_BASE_URL}/${repoName}/pull/${pullRequest.number}](https://${GITHUB_BASE_URL}/${repoName}/pull/${pullRequest.number})`,
                              role: 'assistant',
                              annotations: {
                                pulls: [pullRequest],
                              },
                            },
                          ]
                          console.log(pullRequest)
                          setPullRequest(pullRequest)
                          setMessages(newMessages)
                          setFinishedCreatingPullRequest(true)
                          setScrollToBottom(true)

                          toast({
                            title: `Pull request created: https://${GITHUB_BASE_URL}/${repoName}/pull/${pullRequest.number}`,
                            variant: 'default',
                            duration: 5000,
                          })
                        },
                        (error: any) => {
                          
                          toast({
                            title: 'Failed to Create Pull Request',
                            description: `An error occurred while creating the pull request: ${error.message}`,
                            variant: 'destructive',
                            duration: Infinity,
                          })
                        }
                      )
                    }}
                    disabled={
                      isCreatingPullRequest ||
                      !pullRequestTitle ||
                      !pullRequestBody
                    }
                  >
                    Create Pull Request
                  </Button>
                </div>
                {isCreatingPullRequest && (
                  <div className="flex justify-center items-center py-2 h-full">
                    <PulsingLoader size={1} />
                  </div>
                )}
              </TabsContent>
              <TabsContent value="commit-to-pull">
                <div className="flex items-center">
                  <div className="grow">
                    <Label>Base</Label>
                    <Input
                      value={branch}
                      onChange={(e) => setBranch(e.target.value)}
                      className="w-full mb-4 text-zinc-300 grow"
                    />
                  </div>
                  <FaArrowLeft className="mx-4" />
                  <div className="grow">
                    <Label>Head</Label>
                    <Input
                      value={localFeatureBranch || ''}
                      onChange={(e) =>
                        setMessages(
                          messages.map((message, index) => {
                            if (index === messages.length - 1) {
                              return {
                                ...message,
                                annotations: {
                                  ...message.annotations,
                                  featureBranch: e.target.value,
                                },
                              }
                            }
                            return message
                          })
                        )
                      }
                      className="w-full mb-4 text-zinc-300 grow"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <Label>Commit message</Label>
                  <Input
                    className="flex items-center"
                    value={commitMessage || ''}
                    onChange={(e) => setCommitMessage(e.target.value)}
                    placeholder="Commit message"
                    maxLength={50}
                  />
                </div>
                <div className="flex justify-end my-2 mt-4">
                  <AlertDialogCancel className="mr-4">Cancel</AlertDialogCancel>
                  <Button
                    id="commit-to-branch-button"
                    variant="primary"
                    onClick={async () => {
                      setIsCreatingPullRequest(true)
                      const file_changes = localAppliedChanges.reduce(
                        (
                          acc: { [key: string]: string },
                          suggestion: CodeSuggestion
                        ) => {
                          acc[suggestion.filePath] = suggestion.newCode
                          return acc
                        },
                        {}
                      )
                      try {
                        let response: Response | undefined
                        response = await authorizedFetch(`/commit_to_branch`, {
                          file_changes: file_changes,
                          base_branch: branch,
                          head_branch: localFeatureBranch,
                          commit_message: pullRequestTitle,
                        })

                        const data = await response!.json()
                        const { sha } = data

                        // for commits, show a different message
                        const newMessages: Message[] = [
                          ...messages.slice(0, index),
                          {
                            ...message,
                            annotations: {
                              ...message.annotations,
                              codeSuggestions: undefined,
                              featureBranch: undefined,
                            },
                          },
                          ...messages.slice(index + 1),
                          {
                            content: `Changes merged into branch ${branch} via [https://${GITHUB_BASE_URL}/${repoName}/commit/${sha}](https://${GITHUB_BASE_URL}/${repoName}/commit/${sha}).`,
                            role: 'assistant',
                          },
                        ]
                        setMessages(newMessages)
                        setIsCreatingPullRequest(false)
                        setFinishedCreatingPullRequest(false)

                        toast({
                          title: `Changes commited to branch ${branch} via https://${GITHUB_BASE_URL}/${repoName}/commit/${sha}.`,
                          variant: 'default',
                          duration: 5000,
                        })

                        // validatePr(pullRequest, newMessages.length - 1)
                      } catch (error: any) {
                        setIsCreatingPullRequest(false)
                        
                        toast({
                          title: `Failed to commit to pull request ${userMentionedPullRequest?.number}`,
                          description: `An error occurred while commiting to the pull request: ${error.message}`,
                          variant: 'destructive',
                          duration: Infinity,
                        })
                      }
                    }}
                    disabled={
                      isCreatingPullRequest ||
                      !pullRequestTitle ||
                      !pullRequestBody
                    }
                  >
                    Commit
                  </Button>
                </div>
                {isCreatingPullRequest && (
                  <div className="flex justify-center items-center py-2">
                    <PulsingLoader size={1} />
                  </div>
                )}
              </TabsContent>
            </Tabs>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    )
  )
}

export {
  ChangesMadeDisplay
}