import { atom } from 'jotai';
import { Message, PrValidationStatus, PullRequest, Repository, StatefulCodeSuggestion } from '../src/lib/types';
import { atomWithUndo } from './atomWithUndo';
import { Snippet } from '@/lib/types';

const createAtom = <T>(initialValue: T) => atom<T>(initialValue); // Do we need this line?

export const createStorageAtom = <T>(key: string, initialValue: T) => {
  // Read from storage only once, unlike atomWithStorage
  const storedValue = typeof window !== 'undefined' ? localStorage.getItem(key) : undefined;
  const initialAtomValue = storedValue ? JSON.parse(storedValue) : initialValue;

  const storageAtom = atom<T, [T], unknown>(
    initialAtomValue,
    (get, set, newValue: T) => {
      set(storageAtom, newValue);
      localStorage.setItem(key, JSON.stringify(newValue));
    }
  );

  return storageAtom;
};

export const messagesState = createAtom<Message[]>([]);
export const currentMessageState = createAtom<string>('');
export const isLoadingState = createAtom<boolean>(false);
export const messagesIdState = createAtom<string>('');
export const snippetsState = createAtom<Snippet[]>([]);
export const repoNameState = createStorageAtom<string>('repoName', '');
export const repoNameValidState = createStorageAtom<boolean>('repoNameValid', false);
export const reposState = createAtom<Repository[]>([]);
export const branchesState = createStorageAtom<string[]>("branches", []);
export const userBranchesState = createStorageAtom<string[]>("userBranches", []);
export const branchState = createAtom<string>('');
export const featureBranchState = createAtom<string | null>(null);
export const finishedCreatingBranchState = createAtom<boolean>(false);
export const streamActiveState = createAtom<boolean>(false);
export const setShowSurveyState = createAtom<boolean>(false);
export const repoNameDisabledState = createAtom<boolean>(false);
export const pullRequestState = createAtom<PullRequest | null>(null);
export const commitToPRState = createAtom<boolean>(false);
export const pullRequestTitleState = createAtom<string | null>(null);
export const pullRequestBodyState = createAtom<string | null>(null);
export const commitMessageState = createAtom<string | null>(null);
export const isCreatingPullRequestState = createAtom<boolean>(false);
export const isRunningCiAtom = createAtom<boolean>(false);
// export const appliedChangesState = createAtom<StatefulCodeSuggestion[]>([]);

export const appliedChangesAtom = createAtom<StatefulCodeSuggestion[]>([]);
export const appliedChangesHistoryAtom = atomWithUndo<StatefulCodeSuggestion[]>(appliedChangesAtom, 10);
export const suggestedChangesState = createAtom<StatefulCodeSuggestion[]>([]);
export const autoScrollState = createStorageAtom<boolean>('autoScroll', true);
export const forkMessageState = createAtom<boolean>(false);
export const searchMessageState = createAtom<string>('');
export const prValidationStatusesState = createAtom<PrValidationStatus[]>([]);
export const scrollToBottomState = createAtom<boolean>(false);
export const doCreatePullRequestState = createStorageAtom<boolean>('doCreatePullRequest', false);
