import { Message, PullRequest, SnakeCaseKeys } from './types'
// @ts-expect-error
import * as Diff from 'diff'
import lodash from 'lodash'

// Helper function to escape special characters in a string for use in a regex
export function escapeRegExp(text: string) {
  return text.replaceAll(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export const renderPRDiffs = (pr: PullRequest) => {
  return pr.file_diffs
    .map((diff) => `@@ ${diff.filename} @@\n${diff.patch}`)
    .join('\n\n')
}

export const sliceLines = (content: string, start: number, end: number) => {
  return content
    .split('\n')
    .slice(Math.max(start - 1, 0), end)
    .join('\n')
}

export function stripTripleQuotes(text: string): string {
  let strippedText = lodash.trim(text, '\n').trimEnd()
  if (lodash.startsWith(strippedText, '```')) {
    strippedText = strippedText.slice(strippedText.indexOf('\n') + 1)
  }
  if (lodash.endsWith(strippedText, '```')) {
    strippedText = strippedText.slice(0, strippedText.lastIndexOf('\n'))
  }
  return strippedText
}

export const getJSONPrefix = (buffer: string): [any[], number] => {
  if (buffer.startsWith('null')) {
    // for heartbeat messages
    return [[], 'null'.length]
  }
  let stack: string[] = []
  const matchingBrackets: { [key: string]: string } = {
    '[': ']',
    '{': '}',
    '(': ')',
  }
  let currentIndex = 0
  const results = []
  let inString = false
  let escapeNext = false

  for (let i = 0; i < buffer.length; i++) {
    const char = buffer[i]

    if (escapeNext) {
      escapeNext = false
      continue
    }

    if (char === '\\') {
      escapeNext = true
      continue
    }

    if (char === '"') {
      inString = !inString
    }

    if (!inString) {
      if (matchingBrackets[char]) {
        stack.push(char)
      } else if (matchingBrackets[stack[stack.length - 1]] === char) {
        stack.pop()
        if (stack.length === 0) {
          try {
            results.push(JSON.parse(buffer.slice(currentIndex, i + 1)))
            currentIndex = i + 1
          } catch {
            continue
          }
        }
      }
    }
  }
  // if (currentIndex == 0) {
  //   console.log(buffer); // TODO: optimize later
  // }
  return [results, currentIndex]
}

export const getFunctionCallHeaderString = (
  functionCall: Message['function_call'],
  presentTense: boolean = false
) => {
  const getVerb = (presentVerb: string, pastVerb: string) =>
    presentTense ? presentVerb : pastVerb

  switch (functionCall?.function_name) {
    case 'analysis': {
      return functionCall.is_complete ? 'Analysis' : 'Analyzing...';
    }
    case 'self_critique': {
      return functionCall.is_complete ? 'Self critique' : 'Self critiquing...';
    }
    case 'vector_search': {
      if (functionCall!.function_parameters?.directory || functionCall!.function_parameters?.directory) {
        return functionCall.is_complete ? `${getVerb('Search', 'Searched')} "${functionCall.function_parameters?.question.trim()}" in ${functionCall.function_parameters?.directory.trim()}` : `Searching  "${functionCall.function_parameters?.question.trim()}" in ${functionCall.function_parameters?.directory.trim()}...`;
      } else if (functionCall!.function_parameters?.glob || functionCall!.function_parameters?.glob) {
        return functionCall.is_complete ? `${getVerb('Search', 'Searched')} "${functionCall.function_parameters?.question.trim()}" with pattern ${functionCall.function_parameters?.glob.trim()}` : `Searching  "${functionCall.function_parameters?.question.trim()}" with pattern ${functionCall.function_parameters?.glob.trim()}...`;
      } else {
        return functionCall.is_complete ? `${getVerb('Search', 'Searched')} "${functionCall.function_parameters?.question.trim()}"` : `Searching "${functionCall.function_parameters?.question.trim()}"...`;
      }
    }
    case 'view_file': {
      if (functionCall.function_parameters?.entities) {
        return functionCall.is_complete ? `${getVerb('Open', 'Opened')} ${functionCall.function_parameters?.file_path.trim()}:${functionCall.function_parameters?.entities.trim()}` : `Viewing file ${functionCall.function_parameters?.file_path.trim()}:${functionCall.function_parameters?.entities.trim()}...`;
      } else {
        return functionCall.is_complete ? `${getVerb('Open', 'Opened')} ${functionCall.function_parameters?.file_path.trim()}` : `Opening file ${functionCall.function_parameters?.file_path.trim()}...`;
      }
    }
    case 'view_entity': {
      return functionCall.is_complete ? `${getVerb('View', 'Viewed')} ${functionCall.function_parameters?.entity.trim()}` : `Viewing ${functionCall.function_parameters?.entity.trim()}...`;
    }
    case 'access_file': {
      if (functionCall.function_parameters?.entities) {
        return functionCall.is_complete ? `${getVerb('Open', 'Opened')} ${functionCall.function_parameters?.file_path.trim()}:${functionCall.function_parameters?.entities.trim()}` : `Viewing file ${functionCall.function_parameters?.file_path.trim()}:${functionCall.function_parameters?.entities.trim()}...`;
      } else {
        return functionCall.is_complete ? `${getVerb('Open', 'Opened')} ${functionCall.function_parameters?.file_path.trim()}` : `Opening file ${functionCall.function_parameters?.file_path.trim()}...`;
      }
    }
    case 'add_files_to_context': {
      return functionCall.is_complete ? `${getVerb('Add', 'Added')} files to context: ${functionCall.function_parameters?.file_names.replace(',', ', ')}` : `Adding files to context: ${functionCall.function_parameters?.file_names.replace(',', ', ')}...`;
    }
    case 'ripgrep': {
      return functionCall.is_complete ? `${getVerb('Grep', 'Grepped')} "${functionCall.function_parameters?.query.trim()}"` : `Grepping "${functionCall.function_parameters?.query.trim()}"...`;
    }
    case 'search_for_files': {
      if (functionCall.function_parameters?.query) {
        return functionCall.is_complete ? `${getVerb('Searching for files', 'Searched for files')} "${functionCall.function_parameters?.query.trim()}"` : `Searching for file "${functionCall.function_parameters?.query.trim()}"...`;
      } else {
        console.error('search_for_files function call has no query');
        return functionCall.is_complete ? `${getVerb('Searching for files', 'Searched for files')} codebase` : `Searching for files...`;
      }
    }
    case 'done_file_search': {
      return functionCall.is_complete ? `${getVerb('Submit', 'Submitted')} answer` : `Submitting answer...`;
    }
    case 'deciding': {
      return functionCall.is_complete ? `${functionCall.thinking?.slice(0, 100)}...` : 'Sweep is thinking...';
    }
    case 'status': {
      return 'Sweep\'s Current Status';
    }
    case 'search_codebase': {
      if (functionCall!.function_parameters?.query) {
        return functionCall.is_complete ? `${getVerb('Search', 'Searched')} "${functionCall.function_parameters.query.trim()}"` : `Searching "${functionCall.function_parameters.query.trim()}"...`;
      } else {
        return functionCall.is_complete ? `${getVerb('Search', 'Searched')} codebase` : 'Searching codebase...';
      }
    }
    case 'update_user': {
      return 'Sweep encountered a problem!';
    }
    default: {
      return `${functionCall?.function_name}(${Object.entries(functionCall?.function_parameters || {}).map(([key, value]) => `${key}="${value}"`).join(', ')})`;
    }
  }
}

export const getDiff = (originalCode: string, newCode: string) => {
  const diffLines = Diff.diffLines(originalCode.trim(), newCode.trim())
  const formattedChange = diffLines
    .map(
      (
        {
          added,
          removed,
          value,
        }: { added?: boolean; removed?: boolean; value: string },
      ): string => {
        let symbol = added ? '+' : (removed ? '-' : ' ')
        const results = symbol + value.trimEnd().replaceAll('\n', '\n' + symbol)
        return results
      }
    )
    .join('\n')
    .trim()
  console.log(formattedChange)
  return formattedChange
}

export const truncate = (str: string, maxLength: number) =>
  str.length > maxLength ? str.slice(0, maxLength) + '...' : str

export const snakeCaseToCamelCase = (str: string) => {
  return str.replaceAll(/([_]+)([a-z])/g, (match, p1, p2) => p2.toUpperCase())
}

export function toCamelCaseKeys<A extends string, B>(
  obj: SnakeCaseKeys<Record<A, B>>
): Record<A, B> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      snakeCaseToCamelCase(key),
      value,
    ])
  ) as Record<A, B>
}

export const camelCaseToSnakeCase = (str: string) => {
  return str.replaceAll(/([A-Z])/g, '_$1').toLowerCase()
}

export function toSnakeCaseKeys<A extends string, B>(
  obj: Record<A, B>
): SnakeCaseKeys<Record<A, B>> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      camelCaseToSnakeCase(key),
      value,
    ])
  ) as SnakeCaseKeys<Record<A, B>>
}

export const getDiffMetadata = (suggestion: {
  originalCode: string
  newCode: string
}) => {
  let diffLines = Diff.diffLines(
    suggestion.originalCode.trim(),
    suggestion.newCode.trim()
  )
  let numLinesAdded = 0
  let numLinesRemoved = 0
  let numCharsAdded = 0
  let numCharsRemoved = 0
  for (const line of diffLines) {
    if (line.added) {
      numLinesAdded += line.value
        .split('\n')
        .filter((l: string) => l.trim().length > 0).length
      numCharsAdded += line.value.replaceAll(/\s/g, '').length
    } else if (line.removed) {
      numLinesRemoved += line.value
        .split('\n')
        .filter((l: string) => l.trim().length > 0).length
      numCharsRemoved += line.value.replaceAll(/\s/g, '').length
    }
  }
  const firstLines = truncate(
    suggestion.originalCode.split('\n').slice(0, 1).join('\n') ||
    suggestion.newCode.split('\n').slice(0, 1).join('\n'),
    60
  )
  return {
    numLinesAdded,
    numLinesRemoved,
    numCharsAdded,
    numCharsRemoved,
    firstLines,
  }
}

export const getLanguageFromPath = (path: string) => {
  const extension = path.split('.').pop()
  return extension
}
