import { Message, PullRequest, Snippet, StatefulCodeSuggestion } from '@/lib/types'
import {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  FaArrowRight,
  FaBrain,
  FaCheck,
  FaCode,
  FaCommentDots,
  FaExclamationTriangle,
  FaFileCode,
  FaICursor,
  FaPencilAlt,
  FaPlus,
  FaSearch,
  FaSignal,
  FaTimes,
} from 'react-icons/fa'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import {
  CODE_CHANGE_PATTERN,
  MarkdownRenderer,
} from '@/components/shared/MarkdownRenderer'
import { roleToColor } from '@/lib/constants'
import { getFunctionCallHeaderString } from '@/lib/strUtils'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion' // TODO: swap this over later
import SyntaxHighlighter from '@/lib/syntaxHighlighter'
import PrValidationStatusesDisplay from './PrValidationStatusesDisplay'
import PullRequestDisplay from './PullRequestDisplay'
import PulsingLoader from './shared/PulsingLoader'
import { SnippetBadge } from './shared/SnippetBadge'

// @ts-expect-error
import { ScrollArea } from './ui/scroll-area'
import { Progress } from './ui/progress'
import CopyButton from './CopyButton'
import RegenerateChat from './shared/RegenerateChat'
import { useSession } from '@/hooks/useSession'
import { FaFolderTree } from 'react-icons/fa6'
import { appliedChangesAtom, branchState, commitToPRState, featureBranchState, finishedCreatingBranchState, forkMessageState, isCreatingPullRequestState, isLoadingState, messagesIdState, messagesState, prValidationStatusesState, pullRequestBodyState, pullRequestTitleState, repoNameState, suggestedChangesState } from '@/state/atoms'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { Octokit } from '@octokit/rest'
import parsePullRequests from '@/lib/parsePullRequest'
import { isPullRequestEqual } from '@/lib/pullUtils'
import { parseFiles } from '@/lib/parseFiles'
import { getNewBranchName } from '@/lib/appUtils'
import { ChangesMadeDisplay } from './ChangesMadeDisplay'
import React from 'react'

const iconProps = {
  className: 'inline-block mr-2',
}
const iconMapping: { [key: string]: React.ReactNode } = {
  deciding: <FaCommentDots {...iconProps} />,
  view_file: <FaFileCode {...iconProps} />,
  view_entity: <FaCode {...iconProps} />,
  vector_search: <FaSearch {...iconProps} />,
  search_for_files: <FaFolderTree {...iconProps} />,
  ripgrep: <FaICursor {...iconProps} />,
  add_files_to_context: <FaPlus {...iconProps} />,
  done_file_search: <FaCheck {...iconProps} />,
  analysis: <FaBrain {...iconProps} />,
  default: <FaCheck {...iconProps} />,
  update_user: <FaExclamationTriangle {...iconProps} />,
  status: <FaSignal {...iconProps} />,
}

const getIconFromMessage = (message: Message) => {
  return (
    iconMapping[message.function_call?.function_name!] || iconMapping['default']
  )
}

const messageDidError = (message: Message) => {
  return (
    message.content.trimStart().startsWith('ERROR') ||
    (message.content.split('\n')[1] || '').startsWith('No results found') ||
    message.content.trimStart().startsWith("You've already ran") ||
    (message.function_call?.function_name == 'vector_search' &&
      (message.function_call?.snippets?.length || Infinity) <=
        message.content
          .split('\n')
          .filter((line) =>
            line.startsWith('Snippet already retrieved previously:')
          ).length)
  )
}

const toolDidComplete = (message: Message) => {
  return (
    message.role == 'function' &&
    message.content.length > 0 &&
    message.function_call?.function_name != 'analysis' &&
    message.function_call?.function_name != 'update_user' &&
    message.function_call?.function_name != 'done_file_search'
  )
}

const UserMessageDisplay = memo(({
  repoName,
  branch,
  message,
  onEdit,
  currentOwnerOfChatRef,
}: {
  repoName: string
  branch: string
  message: Message
  onEdit: (content: string) => void
  currentOwnerOfChatRef: MutableRefObject<string>
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedContent, setEditedContent] = useState(message.content)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const { username } = useSession()

  const handleClick = useCallback(() => {
    setIsEditing(true);
    // Reset editedContent to original message content when entering edit mode
    if (!isEditing) {
      setEditedContent(message.content);
    }
  }, [isEditing, message.content]);

  const handleBlur = () => {
    setIsEditing(false)
  }

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [editedContent, textareaRef])

  useEffect(() => {
    setEditedContent(message.content)
  }, [message])

  const messageContents = useMemo(() => {
    let currentContent = message.content.trim()
    const files = message.annotations?.files || {}
    for (const filePath in files || {}) {
      if (files[filePath] != undefined) {
        currentContent = currentContent.replace(filePath, `\`${filePath}\``)
      }
    }
    return (
      <MarkdownRenderer
        repoName={repoName}
        branch={branch}
        content={currentContent}
        className="reactMarkdown mt-0 !pt-0 mb-0 !pb-0 break-words"
      />
    )
  }, [message.content, message.annotations?.files])

  return (
    <>
      <div className="flex justify-end">
        {!isEditing && (
          <>
            <FaPencilAlt
              className="inline-block text-zinc-400 mr-2 mt-3 hover:cursor-pointer hover:text-zinc-200 hover:drop-shadow-md"
              onClick={handleClick}
            />
            <CopyButton
              value={message.content}
              className="hover:bg-transparent text-zinc-400 hover:text-zinc-200"
            />
          </>
        )}
        &nbsp;
        <div
          className="transition-color text-sm p-3 rounded-xl mb-4 inline-block bg-zinc-700 hover:cursor-pointer text-left"
          onClick={handleClick}
        >
          <div className={`text-sm text-white max-w-[800px]`}>
            {message.annotations?.originalQuery && (
              <div className="text-zinc-400">
                {message.annotations?.originalQuery}
              </div>
            )}
            {isEditing ? (
              <Textarea
                className="w-full mb-4 bg-zinc-700 text-white w-[800px]"
                ref={textareaRef}
                value={editedContent}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
                    currentOwnerOfChatRef.current =
                      username || ''
                    e.preventDefault()
                    e.stopPropagation()
                    onEdit(editedContent)
                    setIsEditing(false)
                    return
                  }
                }}
                onChange={(e) => {
                  setEditedContent(e.target.value)
                  e.target.style.height = 'auto'
                  e.target.style.height = `${e.target.scrollHeight}px`
                }}
                style={{ height: (editedContent.split('\n').length + 1) * 16 }}
                autoFocus
              />
            ) : (
              messageContents
            )}
          </div>
          {isEditing && (
            <div className="flex justify-end">
              <Button
                onClick={(e) => {
                  handleBlur()
                  e.stopPropagation()
                  e.preventDefault()
                }}
                variant="secondary"
                className="bg-zinc-800 text-white"
              >
                <FaTimes />
              </Button>
              <Button
                onClick={(e) => {
                  onEdit(editedContent)
                  currentOwnerOfChatRef.current = username || ''
                  setIsEditing(false)
                  handleBlur()
                  e.stopPropagation()
                  e.preventDefault()
                }}
                variant="default"
                className="ml-2 bg-blue-900 text-white hover:bg-blue-800"
              >
                <FaArrowRight />
              </Button>
            </div>
          )}
        </div>
      </div>
      {((message.annotations?.pulls && message.annotations.pulls.length > 0) ||
        (message.annotations?.files &&
          Object.entries(message.annotations.files).length > 0)) && (
        <div className="flex flex-col items-end mb-4">
          <div className="overflow-x-auto pb-4">
            {message.annotations?.pulls?.map((pr) => (
              <div className="flex justify-end text-sm" key={pr.number}>
                <PullRequestDisplay pr={pr} />
              </div>
            ))}
            {message.annotations?.files &&
              Object.entries(message.annotations.files).map(
                ([filePath, fileContent], index) =>
                  fileContent && (
                    <div className="flex justify-end mb-2" key={filePath}>
                      <SnippetBadge
                        key={index}
                        snippet={{
                          file_path: filePath,
                          content: fileContent,
                          start: 0,
                          end: fileContent.split('\n').length,
                          score: 1,
                          type_name: 'source',
                        }}
                        className=""
                        repoName={repoName}
                        branch={branch}
                        snippets={[]}
                      />
                    </div>
                  )
              )}
          </div>
        </div>
      )}
    </>
  )
});

UserMessageDisplay.displayName = 'UserMessageDisplay';

const MessageDisplay = memo(({
  index,
  backIndices,
  stream,
  message,
  snippets,
  className,
  userMentionedPullRequests,
  userMentionedPullRequest,
  setUserMentionedPullRequest_,
  onValidatePR,
  // fixPrValidationErrors,
  currentOwnerOfChatRef,
  setSnippets,
  removeChanges,
  startChatStream,
  chatWithAgent,
  authorizedFetch,
  octokit,
  innerMessages,
}: {
  index: number
  backIndices: number[]
  stream: MutableRefObject<ReadableStreamDefaultReader<Uint8Array> | undefined>
  message: Message
  snippets: Snippet[]
  className?: string
  userMentionedPullRequests: PullRequest[]
  userMentionedPullRequest: PullRequest | undefined,
  setUserMentionedPullRequest_: Dispatch<SetStateAction<PullRequest | undefined>>
  onValidatePR?: (pr: PullRequest) => void
  // fixPrValidationErrors: () => void
  currentOwnerOfChatRef: MutableRefObject<string>
  setSnippets: Dispatch<SetStateAction<Snippet[]>>
  removeChanges: () => void
  startChatStream: (message: string, newMessages: Message[], snippets: Snippet[], annotations: Message["annotations"], branch: string) => Promise<void>
  chatWithAgent: (currentMessages: Message[], currentSnippets: Snippet[], branch: string, currentAppliedChanges: StatefulCodeSuggestion[]) => Promise<void>
  authorizedFetch: (url: string, body?: { [key: string]: any }, options?: RequestInit) => Promise<Response>
  octokit: Octokit | undefined
  innerMessages?: ReactNode
}) => {
  const repoName = useAtomValue(repoNameState)
  const setCommitToPR = useSetAtom(commitToPRState)
  const setIsLoading = useSetAtom(isLoadingState)
  const setForkMessage = useSetAtom(forkMessageState)
  const setFeatureBranch = useSetAtom(featureBranchState)
  const setPullRequestTitle = useSetAtom(pullRequestTitleState)
  const setPullRequestBody = useSetAtom(pullRequestBodyState)
  const setPrValidationStatuses = useSetAtom(prValidationStatusesState)
  const setSuggestedChanges = useSetAtom(suggestedChangesState)
  const setIsCreatingPullRequest = useSetAtom(isCreatingPullRequestState)
  const [messages, setMessages] = useAtom(messagesState)
  const [finishedCreatingBranch, setFinishedCreatingBranch] = useAtom(finishedCreatingBranchState)
  const [,setMessagesId] = useAtom(messagesIdState)
  const [branch, setBranch] = useAtom(branchState)
  const [appliedChanges, setAppliedChanges] = useAtom(appliedChangesAtom)
  const [accordionValue, setAccordionValue] = useState<string | undefined>(
    message.function_call?.is_complete &&
      message.function_call?.function_name !== 'update_user'
      ? undefined
      : 'function'
  )
  useEffect(() => {
    setAccordionValue(
      message.function_call?.is_complete &&
        message.function_call?.function_name !== 'update_user'
        ? undefined
        : 'function'
    )
  }, [
    message.function_call?.is_complete &&
      message.function_call?.function_name !== 'update_user',
  ])

  let matches = Array.from(message.content?.matchAll(CODE_CHANGE_PATTERN) || [])
  if (matches.some((match) => !match.groups?.closingTag)) {
    matches = []
  }
  const onEdit = useCallback(async (content: string) => {
    stream?.current?.cancel()
    stream.current = undefined
    setIsLoading(false)
    setForkMessage(true)

    // PULLS START

    const pulls = await parsePullRequests(
      repoName,
      content,
      octokit!
    )

    let currentBranch = branch
    if (pulls.length > 0) {
      currentBranch = pulls[pulls.length - 1].branch
    }

    console.log(pulls, currentBranch)

    let newPulls =
      userMentionedPullRequests && index > 0
        ? [...userMentionedPullRequests]
        : []

    pulls.forEach((pull1) => {
      if (
        !newPulls.some((pull2) =>
          isPullRequestEqual(pull1, pull2!)
        )
      ) {
        newPulls.push(pull1)
      }
    })

    setCommitToPR(newPulls.length > 0)

    // PULLS END

    // FILES START
    const files = await parseFiles(
      repoName,
      content,
      octokit!,
      currentBranch
    )
    // FILES END

    const newMessages: Message[] = [
      ...messages.slice(0, backIndices[index]),
      {
        ...(message as Message),
        content,
        annotations: { pulls, files },
      },
    ]
    console.log(
      'finished or not:',
      finishedCreatingBranch
    )
    let currentFeatureBranch =
      newMessages.findLast(
        (m) => m.annotations?.featureBranch
      )?.annotations?.featureBranch || currentBranch

    if (
      finishedCreatingBranch &&
      currentFeatureBranch
    ) {
      currentBranch = currentFeatureBranch
      setBranch(currentBranch)
      setFeatureBranch(getNewBranchName())
    }

    setFinishedCreatingBranch(false)

    setMessages(newMessages)
    setIsCreatingPullRequest(false)
    if (index == 0) {
      setSnippets([])
      setMessagesId('')
      setSuggestedChanges([])
      setPullRequestTitle(undefined)
      setPullRequestBody(undefined)
      removeChanges()
      startChatStream(
        content,
        newMessages,
        [],
        {
          pulls,
        },
        currentFeatureBranch || branch
      )
      setPrValidationStatuses([])
    } else {
      startChatStream(
        content,
        newMessages,
        snippets,
        {
          pulls,
        },
        currentFeatureBranch || branch
      )
    }
  }, [
    stream,
    setIsLoading,
    setForkMessage,
    repoName,
    octokit,
    branch,
    userMentionedPullRequests,
    index,
    setCommitToPR,
    messages,
    backIndices,
    message,
    finishedCreatingBranch,
    setBranch,
    setFeatureBranch,
    getNewBranchName,
    setFinishedCreatingBranch,
    setMessages,
    setIsCreatingPullRequest,
    setSnippets,
    setMessagesId,
    setSuggestedChanges,
    setPullRequestTitle,
    setPullRequestBody,
    removeChanges,
    startChatStream,
    setPrValidationStatuses,
    snippets,
  ])

  const onRegenerateChatMessage = useCallback(async () => {
    console.log(backIndices, index)
    const lastUserMessageIndex = messages.findLastIndex(((message, index_) => (message.role === 'user' || message.content.includes("DONE")) && index_ < backIndices[index]));
    
    if (lastUserMessageIndex === -1) {
      console.error('No user message found to regenerate from');
      return;
    }

    // Keep all messages up to and including the last user message
    const newMessages = messages.slice(0, lastUserMessageIndex + 1);

    // Update state
    setMessages(newMessages);
    setSuggestedChanges([]);
    setAppliedChanges([]);
    setFinishedCreatingBranch(false);

    // Determine the current branch
    const currentBranch =
      newMessages
        .findLast((m) => m.annotations?.featureBranch)
        ?.annotations?.featureBranch || branch;

    console.log(
      'Regenerating chat with agent...',
      newMessages,
      snippets,
      currentBranch
    );
    chatWithAgent(
      newMessages,
      snippets,
      currentBranch,
      appliedChanges
    )
  }, [
    backIndices,
    index,
    messages,
    setMessages,
    setSuggestedChanges,
    setAppliedChanges,
    setFinishedCreatingBranch,
    branch,
    snippets,
    chatWithAgent,
    appliedChanges,
  ]);

  const thinkingBlock = useMemo(() => {
    // let thinking =
    //   message.function_call?.thinking?.startsWith("Certainly!")
    //   ? message.function_call?.thinking : message.function_call?.thinking?.includes("<update_user>")
    //   ? message.function_call?.thinking.split("<update_user>")[1].split("</update_user>")[0] : null
    // if (message.function_call?.thinking?.includes("<dependency_tree>")) {
    //   let dependency_tree = message.function_call?.thinking.split("<dependency_tree>")[1]
    //   if (dependency_tree?.includes("</dependency_tree>")) {
    //     dependency_tree = dependency_tree.split("</dependency_tree>")[0]
    //   }
    //   thinking = "## Knowledge Graph\n```\n" + dependency_tree + "\n```\n" + thinking?.trim()
    // }
    const thinking = message.function_call?.thinking
      ?.replace('<update_user>', '')
      .replace('</update_user>', '')
    return (
      thinking && (
        <MarkdownRenderer
          repoName={repoName}
          branch={branch}
          content={thinking || ''}
          className="reactMarkdown py-0 mb-4"
          snippets={snippets}
          disableCodeBlockHover
        />
      )
    )
  }, [message.function_call?.thinking, repoName, branch, snippets])
  const functionCallContentMessage = useMemo(() => {
    const functionCallContent =
      message.function_call?.function_name === 'done_file_search'
        ? (message.function_call?.function_parameters.reason || '') +
          '\n\n' +
          (message.function_call?.function_parameters.existing_patterns || '')
        : message.content
    return (
      message.function_call && (
        <MarkdownRenderer
          repoName={repoName}
          branch={branch}
          content={functionCallContent
            .replace('<ripgrep_response>', '')
            .replace('</ripgrep_response>', '')
            .replace('<file_contents>', '```')
            .replace('</file_contents>', '```')}
          className="reactMarkdown py-0"
        />
      )
    )
  }, [message.content, message.function_call, repoName, branch])
  // custom rendering for update_user function call
  const updateUserContent = useMemo(() => {
    return (
      message.function_call &&
      message.function_call?.function_name === 'update_user' && (
        <>
          {message.function_call?.function_parameters?.issue && (
            <>
              <h2 className="text-lg font-bold mt-4 opacity-90">Issue</h2>
              <MarkdownRenderer
                repoName={repoName}
                branch={branch}
                content={message.function_call.function_parameters.issue}
                className="reactMarkdown py-0 opacity-90"
              />
            </>
          )}
          {message.function_call?.function_parameters?.justification && (
            <>
              <MarkdownRenderer
                repoName={repoName}
                branch={branch}
                content={
                  message.function_call.function_parameters.justification
                }
                className="reactMarkdown py-0 opacity-90"
              />
            </>
          )}
          {message.function_call?.function_parameters?.update && (
            <>
              <h2 className="text-lg font-bold opacity-90">New Approach</h2>
              <MarkdownRenderer
                repoName={repoName}
                branch={branch}
                content={message.function_call.function_parameters.update}
                className="reactMarkdown py-0 opacity-90"
              />
            </>
          )}
        </>
      )
    )
  }, [message.content, message.function_call, repoName, branch])
  const assistantMessage = useMemo(() => {
    return (
      <MarkdownRenderer
        repoName={repoName}
        branch={branch}
        content={message.content}
        metaData={message.annotations}
        className="reactMarkdown mb-0 py-4 "
        snippets={snippets}
      />
    )
  }, [message.content, message.annotations, repoName, branch, snippets])

  if (message.role === 'user') {
    return (
      <UserMessageDisplay
        repoName={repoName}
        branch={branch}
        message={message}
        onEdit={onEdit}
        currentOwnerOfChatRef={currentOwnerOfChatRef}
      />
    )
  }

  if (message.function_call?.function_name === 'analysis' && import.meta.env.DEV) {
    return 
  }

  if (message.content === 'Loading...') {
    return 
    // return (
    //   <>
    //     <Skeleton className="w-11/12 h-8 mb-3" />
    //     <Skeleton className="w-full h-12 mb-5" />
    //     <Skeleton className="w-3/4 h-9 mb-4" />
    //     <Skeleton className="w-5/6 h-10 mb-3" />
    //   </>
    // )
  }

  // used for conditional rendering below
  return (
    <>
      <div className={`flex justify-start`}>
        {(!message.annotations?.pulls ||
          message.annotations!.pulls?.length == 0) && (
          <div
            className={`transition-color text-sm p-3 rounded-xl mb-4 inline-block text-left
              ${message.role === 'assistant' ? 'py-1 w-full' : ''} 
              ${
                message.role === 'function' &&
                message.function_call?.function_name === 'update_user'
                  ? 'bg-zinc-800 border-2 border-orange-700'
                  : (message.function_call?.function_name === 'deciding' ||
                      message.function_call?.function_name === 'analysis' ||
                      message.function_call?.function_name === 'status'
                    ? 'bg-zinc-800'
                    : className || roleToColor[message.role])
              }`}
          >
            {message.role === 'function' ? (
              <Accordion
                type="single"
                collapsible
                className={`w-full`}
                value={accordionValue}
                onValueChange={setAccordionValue}
              >
                <AccordionItem value="function" className="border-none">
                  <AccordionTrigger className="border-none p-0 w-full">
                    <div
                      className={`flex items-center w-full p-0 rounded-t-md`}
                    >
                      <div className="text-xs text-gray-400 flex items-center text-left">
                        {!message.function_call!.is_complete ? (
                          <PulsingLoader size={0.5} />
                        ) : (
                          getIconFromMessage(message)
                        )}
                      </div>
                      <div
                        className={`text-xs flex items-center text-left ${
                          message.function_call?.function_name === 'update_user'
                            ? 'text-gray-200'
                            : 'text-gray-400'
                        }`}
                      >
                        <span className="text-wrap break-words">
                          {getFunctionCallHeaderString(message.function_call)}
                          {messageDidError(message) ? (
                            <FaTimes
                              className={`mt-[1px] text-red-500 inline ml-2`}
                            />
                          ) : (
                            toolDidComplete(message) && (
                              <FaCheck className={`mt-[1px] inline ml-2`} />
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent
                    className={`pb-0 ${
                      message.content &&
                      message.function_call?.function_name ===
                        'search_codebase' &&
                      !message.function_call?.is_complete
                        ? 'pt-2'
                        : 'pt-0'
                    } pt-2`}
                  >
                    {message.function_call?.function_name ===
                      'search_codebase' &&
                      message.content &&
                      !message.function_call.is_complete && (
                        <span className="p-4 pl-2">{message.content}</span>
                      )}
                    {message.function_call?.thinking &&
                    message.function_call.function_name !== 'update_user' ? (
                      <>
                        {thinkingBlock}
                        {innerMessages}
                      </>
                    ) : message.function_call?.function_name !==
                      'update_user' ? (
                      message.function_call!.snippets &&
                      message.function_call?.snippets &&
                      message.function_call?.snippets.length > 0 ? (
                        <div className="pb-0 pt-2">
                          {message.function_call!.snippets.map(
                            (snippet, index) => (
                              <SnippetBadge
                                key={index}
                                snippet={snippet}
                                className=""
                                repoName={repoName}
                                branch={branch}
                                button={<></>}
                                snippets={[]}
                                setSnippets={() => {}}
                                newSnippets={[]}
                                setNewSnippets={() => {}}
                                options={[]}
                              />
                            )
                          )}
                        </div>
                      ) : message.function_call?.function_name ==
                          'vector_search' &&
                        message.content
                          .trim()
                          .endsWith('embedded for vector search') ? (
                        (() => {
                          const a_slash_b = message.content.split(' ')[0]
                          const [a, b] = a_slash_b.split('/')
                          return (
                            <>
                              <h2 className="mt-4 text-sm">
                                Embedding Codebase... ({a_slash_b})
                              </h2>
                              <Progress
                                value={Math.round(
                                  (parseInt(a) / parseInt(b)) * 100
                                )}
                                className="mt-1 mb-4 h-2 bg-zinc-900 max-w-[800px]"
                                color="blue-700" // bg-blue-700
                              />
                            </>
                          )
                        })()
                      ) : message.function_call!.function_name ===
                          'self_critique' ||
                        message.function_call!.function_name === 'analysis' ||
                        message.function_call!.function_name ===
                          'done_file_search' ||
                        message.function_call!.function_name ===
                          'add_files_to_context' ? (
                        <>
                          <ScrollArea className="max-h-[500px] overflow-y-auto overflow-x-auto">
                            {functionCallContentMessage}
                          </ScrollArea>
                          {message.function_call!.function_name ===
                            'done_file_search' &&
                            import.meta.env.DEV && ( // only available in dev
                              <SyntaxHighlighter
                                language="xml"
                                customStyle={{
                                  backgroundColor: 'transparent',
                                  maxHeight: '300px',
                                }}
                                className="p-4"
                              >
                                {'Final Code Entity Relationship Map:\n\n' +
                                  message.function_call?.function_parameters
                                    .code_entity_relationship_map || ''}
                              </SyntaxHighlighter>
                            )}
                        </>
                      ) : (
                        <SyntaxHighlighter
                          language="xml"
                          customStyle={{
                            backgroundColor: 'transparent',
                            maxHeight: '300px',
                          }}
                          className="p-4"
                        >
                          {message.content}
                        </SyntaxHighlighter>
                      )
                    ) : (
                      <ScrollArea className="max-h-[500px] overflow-y-auto">
                        {updateUserContent}
                      </ScrollArea>
                    )}

                    {/* <FeedbackBlock message={message} index={index} /> */}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            ) : (message.role === 'assistant' ? (
              assistantMessage
            ) : (
              <UserMessageDisplay
                repoName={repoName}
                branch={branch}
                message={message}
                onEdit={onEdit}
                currentOwnerOfChatRef={currentOwnerOfChatRef}
              />
            ))}
          </div>
        )}
        {message.role === 'assistant' &&
          !(
            message.annotations?.pulls && message.annotations?.pulls.length > 0
          ) && (
            <RegenerateChat onRegenerateChatMessage={onRegenerateChatMessage} />
          )}
      </div>
      {message.annotations?.pulls?.map((pr) => (
        <React.Fragment key={pr.number}>
          <div className="flex justify-start text-sm" key={pr.number}>
            <PullRequestDisplay pr={pr} onValidatePR={onValidatePR} />
          </div>
          <div className="rounded-xl bg-zinc-800 p-4 max-w-[800px] w-fit-content mb-4">
            <p>To check out to this branch:</p>
            <div className='relative rounded pr-8 bg-[hsl(240,4%,16%)]'>
              <CopyButton value={`git fetch && git checkout ${pr.branch}`} className="absolute top-2 right-2" />
              <SyntaxHighlighter
                language="bash"
                customStyle={{
                  backgroundColor: 'transparent',
                  maxHeight: '300px',
                }}
                className="text-left text-sm"
              >
                {`git fetch && git checkout ${pr.branch}`}
              </SyntaxHighlighter>
            </div>
          </div>
        </React.Fragment>
      ))}
      {(message as Message).annotations?.featureBranch && (message as Message).annotations?.codeSuggestions && (
        <ChangesMadeDisplay
          index={backIndices[index]}
          userMentionedPullRequest={userMentionedPullRequest}
          setUserMentionedPullRequest_={setUserMentionedPullRequest_}
          userMentionedPullRequests={userMentionedPullRequests}
          setFinishedCreatingPullRequest={setIsCreatingPullRequest}
          authorizedFetch={authorizedFetch}
        />
      )}
      {message.annotations?.prValidationStatuses &&
        message.annotations?.prValidationStatuses.length > 0 && (
          <PrValidationStatusesDisplay
            statuses={message.annotations?.prValidationStatuses}
            // fixPrValidationErrors={fixPrValidationErrors}
          />
        )}
    </>
  )
}, (prevProps, nextProps) => {
  return prevProps.message === nextProps.message && prevProps.index === nextProps.index
})

MessageDisplay.displayName = 'MessageDisplay';

export default MessageDisplay;
