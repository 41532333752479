import { javascript } from '@codemirror/lang-javascript'
import { go } from '@codemirror/lang-go'
import { java } from '@codemirror/lang-java'
import { python } from '@codemirror/lang-python'
import { StreamLanguage } from '@codemirror/language'
import { ruby } from '@codemirror/legacy-modes/mode/ruby'

import dracula from 'react-syntax-highlighter/dist/cjs/styles/prism/dracula'
import { Extension } from '@uiw/react-codemirror'

const GITHUB_BASE_URL = import.meta.env.NEXT_PUBLIC_GITHUB_BASE_URL || "github.com";
const GITHUB_ENTERPRISE = GITHUB_BASE_URL !== "github.com";
const GITHUB_API_BASE_URL = GITHUB_ENTERPRISE ? `${GITHUB_BASE_URL}/api/v3` : `api.${GITHUB_BASE_URL}`; // For enterprise, it's BASE_URL/api/v3 for some reason

const BACKEND_URL = import.meta.env.NEXT_PUBLIC_BACKEND_URL || "";

console.log("GITHUB_BASE_URL:", GITHUB_BASE_URL)
console.log("BACKEND_URL:", BACKEND_URL)

const codeStyle = dracula

export const version = import.meta.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'none'

const fileEndingsToLanguages: { [key: string]: string } = {
  py: 'python',
  ts: 'typescript',
  tsx: 'typescript',
  js: 'javascript',
  jsx: 'javascript',
  go: 'go',
  java: 'java',
  rb: 'ruby',
  php: 'php',
  html: 'html',
  css: 'css',
  scss: 'scss',
  cpp: 'cpp',
  cs: 'csharp',
  kt: 'kotlin',
  rs: 'rust',
  swift: 'swift',
  sql: 'sql',
  sh: 'bash',
  r: 'r',
  pl: 'perl',
  lua: 'lua',
  md: 'markdown',
  json: 'json',
  xml: 'xml',
  yml: 'yaml',
  yaml: 'yaml',
  dart: 'dart',
  coffee: 'coffeescript',
}

const getFileEndingsToLanguages = (
  extension: string,
  defaultLanguage: string = 'javascript'
): string => {
  return fileEndingsToLanguages[extension] || defaultLanguage
}

const modelMap: { [key: string]: string } = {
  'claude-3-5-sonnet-20240620': 'Sonnet 3.5',
  'claude-3-opus-20240229': 'Opus',
  'claude-3-sonnet-20240229': 'Sonnet',
  'claude-3-haiku-20240307': 'Haiku',
  'gpt-4o': 'GPT-4o',
  'deepseek-coder': 'DeepSeek Coder V2',
  'meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo': 'Meta Llama 3.1 70B',
  'mistral-large-latest': 'Mistral Large',
}

const modelCompanyMap: { [key: string]: (keyof typeof modelMap)[] } = {
  Anthropic: ['claude-3-5-sonnet-20240620', 'claude-3-opus-20240229', 'claude-3-sonnet-20240229', 'claude-3-haiku-20240307'],
  OpenAI: ['gpt-4o'],
  DeepSeek: ['deepseek-coder'],
  Meta: ['meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo'],
  Mistral: ['mistral-large-latest'],
}

const roleToColor = {
  user: 'bg-zinc-800',
  assistant: '',
  function: 'bg-zinc-800',
}

const typeNameToColor = {
  source: 'bg-[#15295e]', // Darkened bg-blue-900
  tests: 'bg-[#0f3d22]', // Darkened bg-green-900
  dependencies: 'bg-[#3f3f46]', // Original bg-zinc-600
  tools: 'bg-[#451366]', // Darkened bg-purple-900
  docs: 'bg-[#57310e]', // Darkened bg-yellow-900
}

const languageMapping: { [key: string]: Extension } = {
  js: javascript(),
  jsx: javascript({ jsx: true }),
  ts: javascript({ typescript: true }),
  tsx: javascript({ typescript: true, jsx: true }),
  go: go(),
  py: python(),
  java: java(),
  rb: StreamLanguage.define(ruby),
}

const DEFAULT_K: number = 8
const DEFAULT_MODEL = 'claude-3-5-sonnet-20240620'

export {
  GITHUB_BASE_URL,
  GITHUB_API_BASE_URL,
  BACKEND_URL,
  codeStyle,
  modelMap,
  modelCompanyMap,
  DEFAULT_K,
  DEFAULT_MODEL,
  roleToColor,
  typeNameToColor,
  languageMapping,
  fileEndingsToLanguages,
  getFileEndingsToLanguages,
}
