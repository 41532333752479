import React, { useEffect, useMemo, useState } from 'react'
import { Button } from './ui/button'
import { currentMessageState } from '@/state/atoms'

import { useAtomValue, useSetAtom } from 'jotai'
import { GITHUB_BASE_URL } from "@/lib/constants";
import { useSession } from '../hooks/useSession'
import { repoNameState } from '../state/atoms'


const getExampleTasks = (): [string[], string[]] => {
  const defaultExamples = [
    'Fix a bug',
    'Review PR',
    'Add unit tests',
    'Add unit tests to PR',
  ]
  const defaultExamplesInputs = [
    'In path/to/file/a.ext we have a bug where <insert bug description>. This bug happens when <insert bug conditions>. The desired behaviour is <insert desired behaviour>. Explain why this bug is happening and propose a fix.',
    'Check this PR for stylistic issues and potential bugs: <pr_link_goes_here>',
    'Add unit tests for path/to/file/a.ext, you can look at path/to/file/b_tests.ext as an example.',
    'In <pr_link_goes_here>, add unit tests for path/to/file/a.ext, you can look at path/to/file/b_tests.ext as an example.',
  ]

  let exampleTasks
  try {
    exampleTasks = import.meta.env.NEXT_PUBLIC_EXAMPLE_TASKS
      ? JSON.parse(import.meta.env.NEXT_PUBLIC_EXAMPLE_TASKS)
      : defaultExamples
  } catch {
    exampleTasks = defaultExamples
  }

  let exampleTasksInputs
  try {
    exampleTasksInputs = import.meta.env.NEXT_PUBLIC_EXAMPLE_TASKS_INPUTS
      ? JSON.parse(import.meta.env.NEXT_PUBLIC_EXAMPLE_TASKS_INPUTS)
      : defaultExamplesInputs
  } catch {
    exampleTasksInputs = defaultExamplesInputs
  }

  return [exampleTasks, exampleTasksInputs]
}

const ExampleTasks = () => {
  const [latestPRLink, setLatestPRLink] = useState<string | undefined>()
  const setCurrentMessage = useSetAtom(currentMessageState)
  const repoName = useAtomValue(repoNameState)

  const { accessToken, octokit } = useSession()

  const [exampleTasks, exampleTasksInputs] = useMemo(getExampleTasks, [])

  useEffect(() => {
    const fetchLatestPR = async () => {
      if (!accessToken || !repoName || !octokit) return

      try {
        const [owner, repo] = repoName.split('/')
        const response = await octokit.rest.pulls.list({
          owner,
          repo,
          state: 'open',
          sort: 'created',
          direction: 'desc',
          per_page: 1,
        })

        if (response.data.length > 0) {
          const prNumber = response.data[0].number
          setLatestPRLink(`https://${GITHUB_BASE_URL}/${repoName}/pull/${prNumber}`)
        }
      } catch (error) {
        console.error('Error fetching latest PR:', error)
      }
    }

    fetchLatestPR()
  }, [accessToken, repoName])

  const handleTaskClick = useMemo(() => (task: string) => {
    if (latestPRLink) {
      task = task.replace('<pr_link_goes_here>', latestPRLink)
    }
    setCurrentMessage(task)
    setTimeout(() => {
      const textarea = document.querySelector(
        '.sweep-chat-text-area'
      ) as HTMLTextAreaElement
      if (textarea) {
        textarea.focus()
        textarea.style.height = 'auto'
        textarea.style.height = `${textarea.scrollHeight}px`
        textarea.setSelectionRange(textarea.value.length, textarea.value.length)
      }
    }, 0)
  }, [latestPRLink, setCurrentMessage])

  return (
    <>
      <div className="example-tasks max-w-3xl mx-auto text-center h-full items-center flex flex-row justify-around pt-8 space-x-8">
        {exampleTasks?.map((task: string, index: number) => (
          <Button
            key={index}
            className="border bg-zinc-800 p-4 px-8 rounded-lg text-white text-left hover:bg-zinc-800"
            size="lg"
            onClick={() =>
              exampleTasksInputs &&
              exampleTasksInputs[index] &&
              handleTaskClick(exampleTasksInputs[index])
            }
          >
            {task}
          </Button>
        ))}
      </div>
    </>
  )
}

export default ExampleTasks