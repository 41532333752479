import { uniqBy } from "lodash"
import { useEffect } from "react"
import { useSession } from "./useSession"
import { useAtom, useAtomValue } from "jotai"
import { branchesState, repoNameState, userBranchesState } from "@/state/atoms"
import useAuthorizedFetch from "./useAuthorizedFetch"

export const useBranches = () => {
  const { accessToken, octokit } = useSession()

  const repoName = useAtomValue(repoNameState) 
  const authorizedFetch = useAuthorizedFetch()
  const [branches, setBranches] = useAtom(branchesState)
  const [userBranches, setUserBranches] = useAtom(userBranchesState)

  useEffect(() => {
    if (repoName && accessToken) {
      const [owner, repo] = repoName.split('/')
      let currentBranches: string[] = []
      const fetchBranchesFromBackend = async () => {
        try {
          // These are branches from the backend, which prioritize the branches last touched by the user.
          const response = await authorizedFetch(`/branches`, {
            repo_name: repoName,
          })

          const branches = await response.json()
          setUserBranches(branches);
          return branches;
        } catch (e) {
          console.error(e)
          return []
        }
      };
      const fetchBranchesFromOctokit = async () => {
        // These are branches from the Octokit API, which are all the branches in the repo.
        const branches: string[] = [];
        let iteration = 0;
        for await (const response of octokit.paginate.iterator(
          octokit.rest.repos.listBranches,
          {
            owner,
            repo,
            per_page: 100,
          }
        )) {
          branches.push(...response.data.map(branch => branch.name));
          currentBranches = uniqBy([...currentBranches, ...branches], branch => branch.toLowerCase());
          setBranches(currentBranches)
          iteration++;
          if (iteration >= 5) break;
        }
        return branches;
      };

      (async () => {
        try {
          // TODO: fix the sort ordering problem
          const [branchesFromBackend, branchesFromOctokit] = await Promise.all([fetchBranchesFromBackend(), fetchBranchesFromOctokit()]);
          currentBranches = uniqBy([...currentBranches, ...branchesFromBackend, ...branchesFromOctokit], branch => branch.toLowerCase());
          setBranches(currentBranches)
        } catch {
          const [owner, repo] = repoName.split('/')
          const defaultBranch = await octokit.rest.repos.get({owner, repo})
          currentBranches = [defaultBranch.data.default_branch, ...currentBranches]
          setBranches(currentBranches)
        }
      })();
    }
  }, [repoName, accessToken])

  return {
    branches,
    userBranches,
  }
}
