import { FaInfoCircle, FaTimes } from 'react-icons/fa';
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '@/components/ui/alert-dialog';
import { Button } from './ui/button';

export default function SweepExplanation() {
  return (
    <AlertDialog>
      <div className="flex items-center justify-start text-gray-500 max-w-[800px] mt-1">
        <AlertDialogTrigger className="w-full text-left p-2 focus:outline-none px-auto">
          <div className="flex items-center">
            <FaInfoCircle className="mr-2 text-xs" />
            <h3 className="text-sm font-semibold">How does Sweep work?</h3>
          </div>
        </AlertDialogTrigger>
      </div>
      <AlertDialogContent className="p-8 max-w-[800px]">
        <AlertDialogHeader>
          <AlertDialogTitle>How Sweep Works</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          <p className="text-gray-300 mb-4">
            Sweep is an AI-powered code assistant with access to your entire codebase. Here&apos;s what you need to know:
          </p>
          <ul className="text-left text-gray-300 list-disc list-inside mb-4">
            <li>Sweep can read and analyze all files in your repository</li>
            <li>It understands the structure and relationships between different parts of your code</li>
            <li>Sweep can suggest changes by creating new files and modify existing ones</li>
            <li>For best results, review Sweep's rewritten request and delete, add or modify it.</li>
          </ul>
          <p className="text-gray-300 mb-4">
            Simply ask Sweep about your code, request changes, or seek explanations, and it will leverage its understanding of your codebase to assist you.
          </p>
        </AlertDialogDescription>
        <AlertDialogHeader>
          <AlertDialogTitle>How to use Sweep</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          <ul className="text-left text-gray-300 list-disc list-inside mb-4">
            <strong>1. Using Sweep through: {window.location.href}</strong>
            <br/><br/>You can directly ask Sweep to make changes on a branch, pull request, or from your main branch. 
            <br/><br/>
            <strong>2. Assigning GitHub Issues to Sweep</strong>
            <br/><br/>Create an issue with the title starting with &quot;Sweep: &quot; or add the &quot;Sweep&quot; label to an existing issue. Sweep will automatically analyze and work on these issues.
            <br/><br/>
            <strong>3. Assigning GitHub Comments to Sweep</strong>
            <br/><br/>Comment on Sweep&apos;s pull requests or issues by prefixing your comment with &quot;Sweep: &quot;. Sweep will respond to these comments and make necessary adjustments.
            <br/><br/>
          </ul>
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <Button variant="ghost">
              <FaTimes />
            </Button>
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}