import React from 'react'
import { getDiffMetadata } from '@/lib/strUtils'
import { StatefulCodeSuggestion } from '@/lib/types'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import {
  FaChevronDown,
  FaChevronRight,
  FaEdit,
  FaExclamationTriangle,
  FaTimes,
} from 'react-icons/fa'
import CodeMirrorSuggestionEditor from './CodeMirrorSuggestionEditor'
import { MarkdownRenderer } from './shared/MarkdownRenderer'
import { Button } from './ui/button'
import CopyButton from './CopyButton'
import DiffViewer from '@/components/DiffViewer'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from './ui/alert-dialog'
import { toast } from './ui/use-toast'
import { Input } from './ui/input'
import { useAtomValue } from 'jotai'
import { streamActiveState } from '@/state/atoms'

function SuggestedChangeDisplay({
  repoName,
  branch,
  suggestion,
  isAutoFixing,
  setSuggestedChange,
  removeSuggestedChange,
}: {
  repoName: string
  branch: string
  suggestion: StatefulCodeSuggestion
  isAutoFixing: boolean
  setSuggestedChange: Dispatch<SetStateAction<StatefulCodeSuggestion>>
  removeSuggestedChange?: () => void
}) {
  const [collapsed, setCollapsed] = useState(false)
  const [currentNewCode, setCurrentNewCode] = useState(suggestion.newCode)
  const [currentFilePath, setCurrentFilePath] = useState(suggestion.filePath)
  const {
    numLinesAdded,
    numLinesRemoved,
    firstLines,
  } = useMemo(() => getDiffMetadata(suggestion), [suggestion])
  const isStreamActive = useAtomValue(streamActiveState)

  useEffect(() => {
    setCurrentNewCode(suggestion.newCode)
  }, [suggestion.newCode])

  useEffect(() => {
    setCurrentFilePath(suggestion.filePath)
  }, [suggestion.filePath])

  const handleFileNameClick = (e: React.MouseEvent) => {
    const content = e.currentTarget.textContent ?? '';
    if (content) {
      e.stopPropagation()
      navigator.clipboard.writeText(content)
      toast({
        title: 'File path copied to clipboard',
        description: content,
        duration: 3000,
      })
    }
  }

  return (
    <div className="text-white fit-content mb-6">
      <div
        className={`flex justify-between items-center w-full text-sm p-2 px-4 rounded-t-md ${
          suggestion.state === 'done'
            ? 'bg-green-900'
            : suggestion.state === 'error'
              ? 'bg-red-900'
              : suggestion.state === 'pending'
                ? 'bg-zinc-800'
                : 'bg-yellow-800'
        }`}
      >
        <div
          className="flex items-center hover:cursor-pointer"
          onClick={() => {
            if (setCollapsed) {
              setCollapsed(!collapsed)
            }
          }}
        >
          {setCollapsed != undefined &&
            (collapsed ? (
              <FaChevronRight
                className="inline mr-4"
                style={{ marginTop: -2 }}
              />
            ) : (
              <FaChevronDown
                className="inline mr-4"
                style={{ marginTop: -2 }}
              />
            ))}
          <div className="flex flex-col items-start whitespace-pre-wrap break-all">
            <span className="text-white font-mono text-md font-bold">
              <span
                className="hover:bg-gray-200/10 cursor-pointer py-0.5 px-1 rounded-md"
                onClick={handleFileNameClick}
              >
                {suggestion.filePath.split('/').pop()}
              </span>{' '}
              {numLinesAdded > 0 && (
                <span className="text-green-500">+{numLinesAdded}</span>
              )}{' '}
              {numLinesRemoved > 0 && (
                <span className="text-red-500">-{numLinesRemoved}</span>
              )}{' '}
            </span>
            <span
              className="text-white font-mono text-xs hover:bg-gray-200/10 cursor-pointer py-0.5 px-1 rounded-md"
              onClick={handleFileNameClick}
            >
              {suggestion.filePath}
            </span>
            <span
              className="text-zinc-400 font-mono text-xs"
              hidden={!collapsed}
            >
              {firstLines}
            </span>
          </div>

        </div>
        <div className="flex justify-end items-center">
          {suggestion.error && (
            <HoverCard openDelay={300} closeDelay={200}>
              <HoverCardTrigger>
                <FaExclamationTriangle
                  className="hover:cursor-pointer mr-2 text-yellow-500"
                  style={{ marginTop: 2 }}
                />
              </HoverCardTrigger>
              <HoverCardContent className="w-[800px] max-h-[500px] overflow-y-auto">
                <MarkdownRenderer
                  repoName={repoName}
                  branch={branch}
                  content={`**This patch could not be directly applied. We're sending the LLM the following message to resolve the error:**\n\n${suggestion.error}`}
                />
              </HoverCardContent>
            </HoverCard>
          )}
          <div className="flex items-center justify-center">
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  className="bg-transparent hover:bg-zinc-200 hover:bg-opacity-20 text-white px-3 py-1"
                  size="sm"
                  disabled={isAutoFixing || isStreamActive}
                >
                  <FaEdit />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent
                className="max-w-[1200px]"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    <Input
                      value={currentFilePath}
                      onChange={(e) => setCurrentFilePath(e.target.value)}
                      className="font-mono text-sm"
                    />
                  </AlertDialogTitle>
                </AlertDialogHeader>
                <div className="py-4">
                  <CodeMirrorSuggestionEditor
                    suggestion={suggestion}
                    setSuggestedChange={(suggestedChange) => {
                      if (typeof suggestedChange === 'function') {
                        setSuggestedChange((prevState) => {
                          const newState = suggestedChange(prevState);
                          setCurrentNewCode(newState.newCode);
                          return newState;
                        });
                      } else {
                        setCurrentNewCode(suggestedChange.newCode);
                      }
                    }}
                    splitDiff
                  />
                </div>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction asChild>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setSuggestedChange(prev => new StatefulCodeSuggestion({
                          ...prev,
                          filePath: currentFilePath,
                          newCode: currentNewCode,
                        }))
                      }}
                    >
                      Save
                    </Button>
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            <CopyButton value={suggestion.newCode}/>
          </div>
          {removeSuggestedChange && (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  className="bg-red-800 hover:bg-red-700 text-white ml-2"
                  size="sm"
                  disabled={isAutoFixing}
                >
                  <FaTimes />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Remove Suggested Change</AlertDialogTitle>
                  <AlertDialogDescription>
                    Are you sure you want to remove this suggested change? This action cannot be undone.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction asChild>
                    <Button
                      variant="destructive"
                      onClick={removeSuggestedChange}
                      className="bg-red-600 hover:bg-red-700 text-white"
                    >
                      Yes, Remove Change
                    </Button>
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
      </div>
      {!collapsed && (
        <div className="w-full max-h-[400px] overflow-y-auto">
          <DiffViewer
            suggestion={suggestion}
            splitDiff={false}
          />
        </div>
      )}
    </div>
  )
}

export default SuggestedChangeDisplay
