import { FaBug } from 'react-icons/fa'
import { Button } from '../ui/button'
import { memo, useState } from 'react'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card'
import { Textarea } from '../ui/textarea'
import { toast } from '../ui/use-toast'
import { posthog } from '@/lib/posthog'

export const ReportBug = memo(() => {
  const [isOpen, setIsOpen] = useState(false)
  const [feedback, setFeedback] = useState('')
  const surveyID = import.meta.env.NEXT_PUBLIC_SURVEY_ID

  const handleFeedbackSubmit = () => {
    setIsOpen(false)
    posthog.capture('survey sent', {
      $survey_id: surveyID,
      $survey_response: feedback,
    })
    toast({
      title: 'Bug Report Submitted',
      description:
        "Thank you for your feedback! We'll shoot you a message if we have any other questions and let you know when it's fixed!",
    })
  }

  return (
    <div className="fixed bottom-0 right-0 p-4 z-10">
      {!isOpen ? (
        <Button
          variant="outline"
          onClick={() => setIsOpen(true)}
          className="shadow-sm shadow-zinc-800"
        >
          <FaBug className="mr-2" />
          Report Bug
        </Button>
      ) : (
        <Card className="w-[500px] z-10 shadow-lg shadow-zinc-900">
          <CardHeader>
            <CardTitle>Report Bug</CardTitle>
          </CardHeader>
          <CardContent>
            <form>
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Textarea
                    id="feedback"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value!)}
                    placeholder="Describe the unexpected behaviour from Sweep"
                  />
                  <span className="text-sm text-zinc-500">
                    The session recording and logs will be attached to this bug
                    report.
                  </span>
                </div>
              </div>
            </form>
          </CardContent>
          <CardFooter className="flex justify-end space-x-2">
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              className="bg-blue-900 text-white hover:bg-blue-800"
              onClick={handleFeedbackSubmit}
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      )}
    </div>
  )
});
ReportBug.displayName = 'ReportBug';
