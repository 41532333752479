// Source: https://armand-salle.fr/post/autocomplete-select-shadcn-ui/

// TODO: write component tests for this

import { CommandGroup, CommandInput, CommandItem, CommandList } from './command'
import { Command as CommandPrimitive } from 'cmdk'
import {
  type KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { Skeleton } from './skeleton'

import { Check } from 'lucide-react'
import { cn } from '../../lib/utils'


export type Option = Record<'value' | 'label', string> & { [key: string]: string }

interface AutoCompleteProps {
  options: Option[]
  emptyMessage: string
  value?: Option
  onValueChange?: (value: Option) => void
  isLoading?: boolean
  onBlur?: (value: string) => void
  validateOption?: (option: string) => boolean
  icon?: React.ReactNode
  disabled?: boolean
  placeholder?: string
}

export const AutoComplete = ({
  options,
  placeholder,
  emptyMessage,
  value,
  onValueChange,
  onBlur,
  validateOption = (_option: string) => true,
  icon,
  disabled = false,
  isLoading = false,
}: AutoCompleteProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [isOpen, setOpen] = useState(false)
  const [selected, setSelected] = useState<Option | undefined>(value as Option)
  const [inputValue, setInputValue] = useState<string>(value?.label || '')

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current
      if (!input) {
        return
      }

      if (!isOpen) {
        setOpen(true)
     }

      if (event.key === 'Enter' && input.value !== '') {
        const optionToSelect = options.find(
          (option) => option.label.toLowerCase() === input.value.toLowerCase()
        )
        if (optionToSelect || validateOption(input.value)) {
          const newOption = optionToSelect || {
            label: input.value,
            value: input.value,
          }
          setSelected(newOption)
          setInputValue(newOption.label)
          onValueChange?.(newOption)
          onBlur?.(newOption.label)
          setOpen(false)
        }
      }

      if (event.key === 'Escape') {
        input.blur()
        setOpen(false)
      }
    },
    [isOpen, options, onValueChange, onBlur]
  )

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setOpen(false)

      if (inputValue.trim() === '') {
        if (selected) {
          setInputValue(selected.label)
        }
      } else {
        // Check if the input matches any option or that it matches a repo pattern (owner/repo)
        const matchedOption = options.find(
          (option) => option.label.toLowerCase() === inputValue.toLowerCase()
        )

        if (matchedOption || validateOption(inputValue)) {
          const newOption = matchedOption || {
            label: inputValue,
            value: inputValue,
          }
          if (!selected || newOption.value !== selected.value) {
            setSelected(newOption)
            onValueChange?.(newOption)
            onBlur?.(newOption.label)
          }
        } else {
          if (selected) {
            setInputValue(selected.label)
          } else {
            setInputValue('')
          }
        }
      }
    }, 200)
  }, [inputValue, selected, options, onValueChange, onBlur])

  const handleFocus = useCallback(() => {
    setOpen(true)
    setInputValue('')
    inputRef.current?.focus()
  }, [])

  const handleSelectOption = useCallback(
    (selectedOption: Option) => {
      setInputValue(selectedOption.label)
      setSelected(selectedOption)
      onValueChange?.(selectedOption)
      onBlur?.(selectedOption.label)
      setOpen(false)

      setTimeout(() => {
        inputRef?.current?.blur()
      }, 0)
    },
    [onValueChange, onBlur]
  )

  useEffect(() => {
    if (value) {
      setInputValue(value.label)
      setSelected(value)
    }
  }, [value?.label, value?.value])

  return (
    <CommandPrimitive onKeyDown={handleKeyDown}>
      <div>
        <CommandInput
          ref={inputRef}
          value={inputValue}
          onValueChange={isLoading ? undefined : setInputValue}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={selected?.label || placeholder}
          disabled={disabled}
          className="text-sm w-56 mt-1"
          icon={icon}
        />
      </div>
      <div className="relative mt-1">
        <div
          className={cn(
            'animate-in fade-in-0 zoom-in-95 absolute top-0 z-10 w-full rounded-md bg-black outline-none border border-zinc-700',
            isOpen ? 'block' : 'hidden'
          )}
        >
          <CommandList
            className="rounded-lg ring-slate-200"
            onMouseDown={(e) => {
              if (e.target !== e.currentTarget) return
              const rect = e.currentTarget.getBoundingClientRect()
              const isScrollbar =
                e.clientX > rect.left + e.currentTarget.clientWidth
              if (isScrollbar) {
                e.preventDefault()
                e.stopPropagation()
              }
            }}
          >
            {isLoading ? (
              <CommandPrimitive.Loading>
                <div className="p-1">
                  <Skeleton className="h-8 w-full" />
                </div>
              </CommandPrimitive.Loading>
            ) : undefined}
            {options.length > 0 && !isLoading ? (
              <CommandGroup>
                {selected && (() => {
                  const selectedOption = options.find(option => option.value === selected.value)
                  return selectedOption && (
                    <CommandItem
                      key={selectedOption.value}
                      value={selectedOption.label}
                      onMouseDown={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                      }}
                      onSelect={() => handleSelectOption(selectedOption)}
                      className='flex w-full items-center gap-2'
                    >
                      <Check className="w-4" />
                      {selectedOption.label}
                    </CommandItem>
                  )
                })()}
                {options
                  .filter(option => option.value !== selected?.value)
                  .map((option) => {
                    const isSelected = selected?.value === option.value
                    return (
                    <CommandItem
                      key={option.value}
                      value={option.label}
                      onMouseDown={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                      }}
                      onSelect={() => handleSelectOption(option)}
                      className={cn(
                        'flex w-full items-center gap-2',
                        !isSelected ? 'pl-8' : undefined
                      )}
                    >
                      {isSelected ? <Check className="w-4" /> : undefined}
                      {option.label}
                    </CommandItem>
                    )
                  })}
              </CommandGroup>
            ) : undefined}
            {!isLoading ? (
              <CommandPrimitive.Empty className="select-none rounded-sm px-2 py-3 text-center text-sm border border-gray-700">
                {emptyMessage}
              </CommandPrimitive.Empty>
            ) : undefined}
          </CommandList>
        </div>
      </div>
    </CommandPrimitive>
  )
}
