import { PrValidationStatus } from '@/lib/types'
import { FaCheck, FaCircle, FaTimes, FaTimesCircle, FaWrench } from 'react-icons/fa'
import AutoScrollArea from './ui/autoscroll'
import { Button } from '@/components/ui/button'
import { useEffect, useState } from 'react'
import { useSetAtom } from 'jotai'
import { currentMessageState } from '@/state/atoms'
import { toast } from './ui/use-toast'

const PrValidationStatusDisplay = ({
  status,
}: {
  status: PrValidationStatus
}) => {
  const [timeDelta, setTimeDelta] = useState(0);

  useEffect(() => {
    const calculateTimeDelta = () => {
      const currentTime = status.endTime > 0 ? status.endTime : Date.now() / 1000;
      setTimeDelta(currentTime - status.startTime);
    };

    calculateTimeDelta();
    const intervalId = setInterval(calculateTimeDelta, 1000);

    return () => clearInterval(intervalId);
  }, [status.endTime, status.startTime]);
  return (
    <div className="flex justify-start">
      <div className="rounded-xl bg-zinc-800 w-full">
        <div className="flex justify-between">
          <h2 className="font-bold text-sm">
            {status.status === 'success' ? (
              <FaCheck
                className="text-green-500 inline mr-2 text-sm"
                style={{ marginTop: -2 }}
              />
            ) : status.status === 'failure' ? (
              <FaTimes
                className="text-red-500 inline mr-2 text-sm"
                style={{ marginTop: -2 }}
              />
            ) : status.status === 'cancelled' ? (
              <FaTimesCircle
                className="text-zinc-500 inline mr-2 text-sm"
                style={{ marginTop: -2 }}
              />
            ) : (
              <FaCircle
                className={`
                  ${
                    {
                      pending: 'text-zinc-500',
                      running: 'text-yellow-500 animate-pulse',
                    }[status.status]
                  } inline mr-2 text-sm
                `}
                style={{ marginTop: -2 }}
              />
            )}
            {status.message} - {status.containerName} 
          </h2>
          <h2 className="text-gray-400 text-sm">
            {typeof timeDelta === 'number' && timeDelta > 0 && !isNaN(timeDelta) && `${Math.round(timeDelta)}s`}
          </h2>
        </div>
        {status.stdout && (
          <AutoScrollArea className="h-[300px] overflow-y-auto mt-4 bg-zinc-900 p-4 rounded-lg">
            <div className="whitespace-pre-wrap text-sm break-all font-mono">
              {status.stdout}
            </div>
          </AutoScrollArea>
        )}
      </div>
    </div>
  )
}

export default function PrValidationStatusesDisplay({
  statuses,
}: {
  statuses: PrValidationStatus[]
}) {
  const setCurrentMessage = useSetAtom(currentMessageState)
  const firstError = statuses.find((status) => status.status === 'failure')?.stdout
  useEffect(() => {
    if (firstError) {
      setCurrentMessage(firstError + "\n\n" + "Help me fix this error.")
      toast({
        title: "There was an error running CI.",
        description: "Sweep might be able to fix the error.",
        duration: Infinity
      })
    }
  }, [firstError, setCurrentMessage])
  if (statuses.length === 0) {
    return 
  }
  return (
    <div className="flex justify-start mb-4">
      <div className="rounded-xl p-4 bg-zinc-800 space-y-4 w-full">
        {statuses.length === 0 ? (
          <p className="text-zinc-500 font-bold">
            I&apos;m monitoring the CI/CD pipeline to validate this PR. This may
            take a few minutes.
          </p>
        ) : (
          <>
            {statuses.map((status, index) => (
              <PrValidationStatusDisplay key={index} status={status} />
            ))}
            {statuses.some((status) => status.status == 'failure') ? (
              <>
                <p className="text-red-500 font-bold">
                  Some tests have failed.
                </p>
                {firstError && (
                  <Button variant="primary" onClick={() => setCurrentMessage(firstError + "\n\n" + "Help me fix this error.")}>
                    <FaWrench className="mr-2" />
                    Fix errors
                  </Button>
                )}
              </>
            ) : (statuses.some(
                (status) =>
                  status.status == 'pending' || status.status == 'running'
              ) ? (
              <p className="text-yellow-500 font-bold">
                Some tests are still running.
              </p>
            ) : (
              <p className="text-green-500 font-bold">All tests have passed.</p>
            ))}
          </>
        )}
      </div>
    </div>
  )
}
