import { GITHUB_BASE_URL, modelCompanyMap, modelMap } from "@/lib/constants";
import { ChatSummary } from "@/lib/types";
import { 
  branchState,
  branchesState, 
  messagesIdState, 
  repoNameDisabledState, 
  repoNameState, 
  reposState, 
  setShowSurveyState, 
} from "@/state/atoms";
import { useSession } from "@/hooks/useSession";
import { Dispatch, SetStateAction, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuLabel, 
  DropdownMenuRadioGroup, 
  DropdownMenuRadioItem, 
  DropdownMenuSeparator, 
  DropdownMenuTrigger 
} from "./ui/dropdown-menu";
import { NavigationMenu, NavigationMenuList } from "./ui/navigation-menu";
import { 
  FaCheck, 
  FaCodeBranch, 
  FaCog, 
  FaComments, 
  FaPencilAlt,
  FaSignOutAlt,
  FaTimes,
  FaTrash
} from "react-icons/fa";
import { ScrollArea } from "./ui/scroll-area";
import { Button } from "./ui/button";
import { AutoComplete } from "./ui/autocomplete";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";
import { 
  Tooltip, 
  TooltipContent, 
  TooltipProvider, 
  TooltipTrigger 
} from "./ui/tooltip";
import { Switch } from "./ui/switch";
import { Input } from "./ui/input";
import { toast } from "./ui/use-toast";
import { Label } from "./ui/label";
import { Link } from "react-router-dom";
import { truncate } from "@/lib/strUtils";
import { formatDistanceToNow } from 'date-fns'
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory, faMessagePlus, faPencilAlt } from '@fortawesome/pro-regular-svg-icons';
import { LucideFolderGit2 } from "lucide-react";
import banner from "../../public/banner.svg";
import { lastLoadedTimestampAtom } from "@/hooks/useFetchMessages";

// we need this custom menu item as we have inputs and buttons that need to be displayed in the dropdown which shadcn doesnt handle well.
const CustomMenuItem = ({ 
  chat, 
  messagesId, 
  isEditing, 
  editedDisplayName, 
  setEditedDisplayName, 
  handleItemClick, 
  handleSaveClick, 
  handleCancelEdit, 
  handleEditClick, 
  handleDeleteClick 
}: {
  chat: ChatSummary;
  messagesId: string;
  isEditing: boolean;
  editedDisplayName: string;
  setEditedDisplayName: Dispatch<SetStateAction<string>>;
  handleItemClick: () => void;
  handleSaveClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleCancelEdit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleEditClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleDeleteClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const isDisabled = chat.messagesId === messagesId;

  const disabledClass = "opacity-80";
  const enabledClass = "hover:bg-accent cursor-pointer";
  const metadataClass = isDisabled ? "text-gray-600" : "text-gray-300";

  return (
    <div 
      className={`relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50
        ${isDisabled ? disabledClass : enabledClass}`}
      onClick={isDisabled ? undefined : handleItemClick}
    >
      <div className="flex-grow">
        {isEditing ? (
          <Input
            value={editedDisplayName}
            onChange={(e) => setEditedDisplayName(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            className="w-full px-2 py-1 border rounded mr-2 text-white"
          />
        ) : (
          <>
            <b className={isDisabled ? "text-gray-500" : ""}>
              {truncate(chat.displayName || chat.initialMessage, 80)}
            </b>
            <span className={metadataClass}>
              &nbsp;created{' '}
              {formatDistanceToNow(new Date(chat.createdAt), {
                addSuffix: true,
              })}
            </span>
            {chat.username && (
              <span className={metadataClass}>&nbsp;by {chat.username}</span>
            )}
          </>
        )}
      </div>
      
      {!isDisabled && (
        <div className="flex">
          {isEditing ? (
            <>
              <Button
                variant="ghost"
                size="sm"
                onClick={handleSaveClick}
                className="hover:bg-green-500 transition-colors mr-1 ml-2"
              >
                <FaCheck className="text-gray-400"/>
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={handleCancelEdit}
                className="hover:bg-red-500 transition-colors mr-1"
              >
                <FaTimes className="text-gray-400"/>
              </Button>
            </>
          ) : (
            <Button
              variant="ghost"
              size="sm"
              onClick={handleEditClick}
              className="transition-colors mr-1 ml-2 hover:bg-blue-700"
            >
              <FaPencilAlt className="text-gray-400"/>
            </Button>
          )}
          <Button
            variant="ghost"
            size="sm"
            onClick={handleDeleteClick}
            className="hover:bg-red-500 transition-colors"
          >
            <FaTrash className="text-gray-400"/>
          </Button>
        </div>
      )}
    </div>
  );
};

const Navbar = memo(({
  previousChats,
  useSearchAgent,
  setUseSearchAgent,
  model,
  setModel,
  repoNameValid,
  onBlur,
  checkBranchExists,
  markChatAsDeleted,
  setPreviousChats
}: {
  previousChats: ChatSummary[]
  useSearchAgent: boolean
  setUseSearchAgent: Dispatch<SetStateAction<boolean>>
  model: keyof typeof modelMap
  setModel: Dispatch<SetStateAction<keyof typeof modelMap>>
  repoNameValid: boolean
  onBlur: (repoName: string) => void
  checkBranchExists: (branch: string) => Promise<boolean>
  markChatAsDeleted: (messagesId: string) => void
  setPreviousChats: Dispatch<SetStateAction<ChatSummary[]>>
}) => {
  const messagesId = useAtomValue(messagesIdState)
  const repoName = useAtomValue(repoNameState)
  const repoNameDisabled = useAtomValue(repoNameDisabledState)
  const repos = useAtomValue(reposState)
  const branches = useAtomValue(branchesState)
  const setShowSurvey = useSetAtom(setShowSurveyState)
  const setRepoName = useSetAtom(repoNameState)

  const [branch, setBranch] = useAtom(branchState);
  const [currentDisplayName, setCurrentDisplayName] = useState<string | undefined>();
  const [isEditing, setIsEditing] = useState(false);
  const [,setEditedDisplayName] = useState<string>('');
  const editableLabelRef = useRef<HTMLLabelElement>(null);
  const setLastLoadedTimestamp = useSetAtom(lastLoadedTimestampAtom)
  const { signOut, username, user } = useSession()

  const cleanedPreviousChats = useMemo(() => {
    return previousChats
      .filter(chat => !chat.isDeleted)
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .map((chat) => ({
        ...chat,
        initialMessage: chat.initialMessage.replaceAll(/ - \d+$/g, ''),
      }))
      .filter(
        (chat, index, self) =>
          index ===
          self.findIndex((t) => t.initialMessage === chat.initialMessage)
      );
  }, [previousChats]);
  const handleItemClick = (messagesId: string) => {
    setLastLoadedTimestamp(0);
    window.location.href = `/c/${messagesId}`;
  };
  const handleEditDisplayName = useCallback((messagesId: string, newDisplayName: string) => {
    console.log("setting displayname", messagesId, newDisplayName)
    setPreviousChats((prevChats) =>
      prevChats.map((chat) =>
        chat.messagesId === messagesId
          ? { ...chat, displayName: newDisplayName }
          : chat
      )
    );
    setCurrentDisplayName(newDisplayName);
  }, [setPreviousChats, setCurrentDisplayName, messagesId]);

  const onBranchBlur = useCallback(async (branch: string) => {
    const exists = await checkBranchExists(branch)
    if (exists) {
      toast({
        title: `Branch ${branch} set successfully`,
      })
    } else {
      toast({
        title: 'Please set a valid branch',
        description: `The specified branch ${branch} does not exist in the repository (did you push it?). Sweep will not work without a valid branch.`,
        variant: 'destructive',
        className: 'invalid-branch-toast',
      })
    }
  }, [checkBranchExists])

  const PreviousChat = memo(({
    chat,
    messagesId,
    onItemClick,
    onDeleteClick,
    onEditDisplayName
  }: {
    chat: ChatSummary;
    messagesId: string;
    onItemClick: (messagesId: string) => void;
    onDeleteClick: (messagesId: string) => void;
    onEditDisplayName: (messagesId: string, newDisplayName: string) => void;
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedDisplayName, setEditedDisplayName] = useState(chat.displayName || chat.initialMessage);

    const handleEditClick = useCallback((e: React.MouseEvent) => {
      e.stopPropagation();
      setIsEditing(true);
    }, []);
    const handleSaveClick = useCallback((e: React.MouseEvent) => {
      e.stopPropagation();
      onEditDisplayName(chat.messagesId, editedDisplayName);
      setIsEditing(false);
    }, [chat.messagesId, editedDisplayName, onEditDisplayName]);
    const handleCancelEdit = useCallback((e: React.MouseEvent) => {
      e.stopPropagation();
      setIsEditing(false);
      setEditedDisplayName(chat.displayName || chat.initialMessage);
    }, [chat.displayName, chat.initialMessage]);
    const handleItemClick = useCallback(() => {
      onItemClick(chat.messagesId);
    }, [chat.messagesId, onItemClick]);

    const handleDeleteClick = useCallback((e: React.MouseEvent) => {
      e.stopPropagation();
      onDeleteClick(chat.messagesId);
    }, [chat.messagesId, onDeleteClick]);

    return (
      <CustomMenuItem
        chat={{
          ...chat,
          displayName: chat.displayName || chat.initialMessage
        }}
        messagesId={messagesId}
        isEditing={isEditing}
        editedDisplayName={editedDisplayName}
        setEditedDisplayName={setEditedDisplayName}
        handleItemClick={handleItemClick}
        handleSaveClick={handleSaveClick}
        handleCancelEdit={handleCancelEdit}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
      />
    );
  });
  PreviousChat.displayName = 'PreviousChat';
  const NEW_SWEEP_MESSAGE_COPY = 'Untitled Sweep Thread';

  useEffect(() => {
    if (!messagesId) {
      setCurrentDisplayName(NEW_SWEEP_MESSAGE_COPY);
    } else {
      const currentChat = cleanedPreviousChats.find(chat => chat.messagesId === messagesId);
      if (currentChat) {
        setCurrentDisplayName(currentChat.displayName || currentChat.initialMessage);
      } else {
        setCurrentDisplayName(NEW_SWEEP_MESSAGE_COPY);
      }
    }
  }, [messagesId, cleanedPreviousChats]);

  useEffect(() => {
    if (isEditing && editableLabelRef.current) {
      editableLabelRef.current.focus();
      window.getSelection()?.selectAllChildren(editableLabelRef.current);
    } else {
      // If not editing, deselect any existing text selection
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
    }
  }, [isEditing, editableLabelRef]);

  return (
    <NavigationMenu className="fixed top-0 left-0 w-[100vw] px-4 py-2 bg-zinc-900">
      <div className="flex items-center justify-between w-[100vw] align-center">
        <div className="flex items-center flex-shrink-0 gap-2">
          <img
            // src="/src/public/banner.svg"
            src={banner}
            width={100}
            height={60}
            alt="Sweep AI Logo"
            className="rounded-lg hover:cursor-pointer box-shadow-md mx-2 mb-[1px] hover:opacity-80"
            onClick={() => {
              window.location.href = '/'
            }}
          />
          <DropdownMenu>
            <DropdownMenuTrigger className="outline-none">
              <p className="text-sm flex items-center hover:bg-transparent hover:opacity-80">
                <FontAwesomeIcon icon={faHistory} className="text-sm mr-2 hover:bg-transparent hover:text-inherit focus:ring-0 transition-none" />
                Previous Chats
              </p>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="center"
              className="mt-4 overflow-y-auto"
            >
              <ScrollArea className="max-h-[600px] overflow-y-auto">
                {cleanedPreviousChats.length > 0 ? (
                  cleanedPreviousChats.map((chat) => (
                    <PreviousChat
                      key={chat.messagesId}
                      chat={chat}
                      messagesId={messagesId}
                      onItemClick={handleItemClick}
                      onDeleteClick={markChatAsDeleted}
                      onEditDisplayName={handleEditDisplayName}
                    />
                  ))
                ) : (
                  <DropdownMenuItem>No history</DropdownMenuItem>
                )}
              </ScrollArea>
            </DropdownMenuContent>
            {/* Warning: these message IDs are stored in local storage.
              If you want to delete them, you will need to clear your browser cache. */}
          </DropdownMenu>
          <Button variant="ghost" size="icon" asChild>
            <Link
              href="/"
              onClick={() => {
                // Redirect to home page
                window.location.href = '/';
              }}
              id="new-chat-button"
              className="hover:bg-transparent hover:text-inherit focus:ring-0 [&_svg]:hover:text-inherit [&_svg]:hover:opacity-80"
            >
              <FontAwesomeIcon icon={faMessagePlus} className="text-xl" />
            </Link>
          </Button>
        </div>
        <div className="flex items-center justify-center grow">
          <div className="flex items-center justify-center min-w-[600px] max-w-[800px] w-full">
              <div className={`px-4 py-2 rounded cursor-pointer ${!isEditing ? 'hover:opacity-80' : ''}`} onClick={() => {
                setEditedDisplayName(currentDisplayName || '');
                setIsEditing(true);
              }}>
                <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                {isEditing ? (
                  <Label
                    ref={editableLabelRef}
                    htmlFor="current-display-name"
                    className="text-lg text-white truncate max-w-[400px] cursor-pointer p-2 selection:bg-gray-700 focus:outline-none"
                    contentEditable
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleEditDisplayName(messagesId, e.currentTarget.textContent || "Untitled Sweep Thread");
                        setIsEditing(false);
                      } else if (e.key === 'Escape') {
                        setEditedDisplayName(currentDisplayName || NEW_SWEEP_MESSAGE_COPY);
                        setIsEditing(false);
                      }
                    }}
                    onBlur={(e) => {
                      handleEditDisplayName(messagesId, e.currentTarget.textContent || "Untitled Sweep Thread");
                      setIsEditing(false);
                    }}
                    autoFocus
                  >
                    {currentDisplayName && currentDisplayName.length > 40
                      ? `${currentDisplayName.slice(0, 37)}...`
                      : currentDisplayName}
                  </Label>
                ) : (
                  <Label htmlFor="current-display-name" className="text-lg text-white truncate max-w-[400px] cursor-pointer p-2">
                    {currentDisplayName && currentDisplayName.length > 40
                      ? `${currentDisplayName.slice(0, 37)}...`
                      : currentDisplayName}
                  </Label>
                )}
              </div>
            {/* )} */}
          </div>
        </div>
        <div className="flex items-center justify-end mx-4">
          <div className="flex">
            {(repoNameValid || messagesId) && (
              <>
                <div
                  id="repository-selector-top-right"
                  className="flex items-center mr-4"
                >
                  <AutoComplete
                    options={repos.map((repo) => ({
                      label: repo.full_name,
                      value: repo.full_name,
                    }))}
                    placeholder="Repository name"
                    emptyMessage="No repositories found (consider installing/reinstalling the GitHub App)"
                    value={{ label: repoName, value: repoName }}
                    onValueChange={(option) => setRepoName(option.value)}
                    disabled={repoNameDisabled}
                    isLoading={repos.length === 0}
                    icon={
                      <LucideFolderGit2
                        className="mr-2 h-4 w-4 shrink-0 opacity-50 mt-[4px]"
                        onClick={() => {
                          if (repoName) {
                            window.open(`https://${GITHUB_BASE_URL}/${repoName}`, '_blank')
                          }
                        }}
                      />
                    }
                    validateOption={(value) => {
                      return /^[A-Za-z0-9_.-]+\/[A-Za-z0-9_.-]+$/.test(value)
                    }}
                    onBlur={onBlur}
                  />
                </div>
                <div className="flex items-center relative">
                  <AutoComplete
                    options={branches.map((branch) => ({
                      label: branch,
                      value: branch,
                    }))}
                    placeholder="Branch name"
                    emptyMessage="No branches found"
                    value={{ label: branch, value: branch }}
                    onValueChange={(option) => setBranch(option.value)}
                    disabled={repoNameDisabled}
                    isLoading={branches.length === 0}
                    icon={
                      <FaCodeBranch
                        className="mr-2 h-4 w-4 shrink-0 opacity-50 mt-[4px] cursor-pointer"
                        onClick={() => {
                          if (branch) {
                            window.open(`https://${GITHUB_BASE_URL}/${repoName}/tree/${branch}`, '_blank')
                          }
                        }}
                      />
                    }
                    onBlur={onBranchBlur}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <NavigationMenuList className="w-full flex justify-between space-x-2">
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="ghost" id="navbar-settings-button" className="hover:bg-transparent">
              <FaCog className="text-xl hover:opacity-80" />
            </Button>
          </DialogTrigger>
          <DialogContent className="w-120 p-16">
            <h2 className="text-2xl font-bold mb-4 text-center">Settings</h2>
            <div className="w-[300px] flex items-center justify-between">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Label htmlFor="use-search-agent" className="cursor-help">
                      Agentic Search
                    </Label>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-xs">
                    <p>
                      Enable for more accurate and context-aware results.
                      Sweep will spend ~2 minutes per request researching your
                      codebase.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <Switch
                id="use-search-agent"
                checked={useSearchAgent}
                onCheckedChange={setUseSearchAgent}
              />
            </div>
            <div className="w-[300px] flex items-center justify-between py-4">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div className="flex items-center justify-between w-full cursor-pointer">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Label
                            htmlFor="change-chat-model"
                            className="cursor-pointer pr-4"
                          >
                            Model
                          </Label>
                        </TooltipTrigger>
                        <TooltipContent className="max-w-xs">
                          <p>
                            We recommend Sonnet 3.5 for all use cases but have
                            provided other models for testing.
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <div
                      className="bg-background text-foreground border border-input hover:bg-accent hover:text-accent-foreground inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 px-4 py-2 min-w-[120px] text-left"
                      id="change-chat-model"
                    >
                      {modelMap[model]}
                    </div>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  {Object.keys(modelCompanyMap).map((company) => (
                    <div key={company}>
                      <DropdownMenuLabel>{company}</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuRadioGroup
                        value={model}
                        onValueChange={(value) =>
                          setModel(value as keyof typeof modelMap)
                        }
                      >
                        {modelCompanyMap[company].map((model) =>
                          <DropdownMenuRadioItem value={model} key={model}>
                            {modelMap[model]}
                          </DropdownMenuRadioItem>
                        )}
                      </DropdownMenuRadioGroup>
                    </div>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </DialogContent>
        </Dialog>

        <DropdownMenu>
          <DropdownMenuTrigger className="outline-none">
            <div className="flex items-center w-12 h-12">
              <img
                id="profile-pic-top-right"
                className="rounded-full w-10 h-10 m-0"
                src={user?.image || ''}
                alt={user?.name || ''}
              />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>
              <p className="text-md font-bold">
                {username || user?.name}
              </p>
            </DropdownMenuLabel>
            {user?.email && (
              <DropdownMenuItem>{user?.email}</DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="cursor-pointer"
              onClick={() => setShowSurvey(true)}
            >
              <FaComments className="mr-2" />
              Feedback
            </DropdownMenuItem>
            <DropdownMenuItem
              id="sign-out-button"
              className="cursor-pointer"
              onClick={() => signOut()}
            >
              <FaSignOutAlt className="mr-2" />
              Sign Out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </NavigationMenuList>
    </NavigationMenu >
  )
});
Navbar.displayName = 'Navbar';

export {
  Navbar
}