type Tail<T extends any[]> = T extends [any, ...infer U] ? U : never

type SnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? U extends Uncapitalize<U>
    ? `${Lowercase<T>}${SnakeCase<U>}`
    : `${Lowercase<T>}_${SnakeCase<U>}`
  : S

type SnakeCaseKeys<T> = {
  [K in keyof T as SnakeCase<string & K>]: T[K] extends object
    ? SnakeCaseKeys<T[K]>
    : T[K]
}

type Repository = any

interface Snippet {
  content: string
  start: number
  end: number
  file_path: string
  type_name: 'source' | 'tests' | 'dependencies' | 'tools' | 'docs'
  score: number
}

interface FileDiff {
  sha: string
  filename: string
  status:
    | 'modified'
    | 'added'
    | 'removed'
    | 'renamed'
    | 'copied'
    | 'changed'
    | 'unchanged'
  additions: number
  deletions: number
  changes: number
  blob_url: string
  raw_url: string
  contents_url: string
  patch?: string | undefined
  previous_filename?: string | undefined
}

interface PullRequest {
  number: number
  repo_name: string
  title: string
  body: string
  labels: string[]
  status: string
  file_diffs: FileDiff[]
  branch: string
}

interface CodeSuggestionData {
  filePath: string
  originalCode: string
  newCode: string
  fileContents: string
}

class CodeSuggestion implements CodeSuggestionData {
  filePath: string
  originalCode: string
  newCode: string
  fileContents: string
  id: string

  constructor(data: CodeSuggestionData & { id?: string }) {
    this.filePath = data.filePath
    this.originalCode = data.originalCode
    this.newCode = data.newCode
    this.fileContents = data.fileContents
    this.id = data.id || ''
  }
}

interface StatefulCodeSuggestionData extends CodeSuggestionData {
  state: 'pending' | 'processing' | 'done' | 'error'
  error?: string
}

class StatefulCodeSuggestion
  extends CodeSuggestion
  implements StatefulCodeSuggestionData
{
  state: 'pending' | 'processing' | 'done' | 'error'
  error?: string

  constructor(data: StatefulCodeSuggestionData & { id?: string }) {
    super(data)
    this.state = data.state
    this.error = data.error
  }
}

interface Message {
  content: string // This is the message content or function output
  role: 'user' | 'assistant' | 'function'
  function_call?: {
    function_name: string
    function_parameters: { [key: string]: any }
    is_complete: boolean
    snippets?: Snippet[]
    thinking?: string
  } // This is the function input
  annotations?: {
    pulls?: PullRequest[]
    files?: { [key: string]: string } // file path to file contents
    codeSuggestions?: StatefulCodeSuggestion[]
    featureBranch?: string
    prValidationStatuses?: PrValidationStatus[]
    snippetCodeMatches?: snippetCodeMatch[]
    originalQuery?: string // The original query before it was rewritten by the LLM
  }
  key?: 'function_call' | 'function_call_output'
}

interface ToolCallGroup {
  // Represents some thinking, followed by a set of tool calls
  messages: Message[]
  thinking: string
}

type ChatItem = ToolCallGroup[] | Message

interface snippetCodeMatch {
  start: number
  end: number
  code: string
  score: number
  language: string
}

interface ChatSummary {
  messagesId: string
  createdAt: string
  initialMessage: string
  username: string
  isDeleted: boolean
  displayName?: string
}

interface PrValidationStatus {
  message: string
  stdout: string
  succeeded: boolean | undefined
  status: 'pending' | 'running' | 'success' | 'failure' | 'cancelled'
  llmMessage: string
  containerName: string
  startTime: number
  endTime: number
}

export type {
  Tail,
  SnakeCase,
  SnakeCaseKeys,
  Repository,
  Snippet,
  FileDiff,
  PullRequest,
  Message,
  ToolCallGroup,
  ChatItem,
  ChatSummary,
  PrValidationStatus,
}

export { CodeSuggestion, StatefulCodeSuggestion }
