import { useCallback } from "react"
import { useSession } from "./useSession"
import { useAtomValue } from "jotai"
import { repoNameState } from "@/state/atoms"
import { BACKEND_URL } from "@/lib/constants"
import { toast } from "@/components/ui/use-toast"
import usePostHogCapture from "./usePosthogCapture"


export default function useAuthorizedFetch() {
  const posthog_capture = usePostHogCapture()
  const { accessToken } = useSession()
  const repoName = useAtomValue(repoNameState)


  return useCallback(
    async (
      url: string,
      body: { [key: string]: any } = {},
      options: RequestInit = {}
    ) => {
      if (!accessToken) {
        throw new Error("Access token is null")
      }

      const maxRetries = 3
      const retryDelay = 3000 // 3 second

      const fetchWithRetry = async (retryCount: number): Promise<Response> => {
        try {
          const response = await fetch(`${BACKEND_URL}/backend${url}`, {
            method: options.method || 'POST',
            headers: {
              ...options.headers,
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body:
              body && options.method !== 'GET'
                ? JSON.stringify({
                    repo_name: repoName,
                    ...body,
                  })
                : undefined,
            ...options,
          })

          if (!response.ok) {
            let errorMessage = `HTTP error! status: ${response.status}`
            let errorDetail = ''
            try {
              const errorData = await response.json()
              errorDetail = errorData.detail || ''
              errorMessage = errorDetail || errorMessage
            } catch (parseError) {
              console.error('Error parsing error response:', parseError)
            }

            // If it's a 502 error and we haven't exceeded max retries, retry the request
            if ((response.status === 502 || response.status === 500) && retryCount < maxRetries && import.meta.env.PROD) {
              console.log(
                `Retrying request (${retryCount + 1}/${maxRetries})...`
              )
              await new Promise((resolve) => setTimeout(resolve, retryDelay))
              return fetchWithRetry(retryCount + 1)
            }

            console.error(url)
            const error = new Error(errorMessage) as Error & {
              status?: number
              detail?: string
              traceback?: string
            }
            error.status = response.status
            error.detail = errorDetail
            throw error
          }

          return response
        } catch (error: any) {
          const validErrorStatus = (error.status === 502 || error.status === 500 || error.status === 400) 
          if (validErrorStatus && retryCount < maxRetries && import.meta.env.PROD) {
            console.log(
              `Encountered a 500, 502, or 400 error. Retrying request (${retryCount + 1}/${maxRetries})...`
            )
            // You might want to update a state here to show this message to the user
            await new Promise((resolve) => setTimeout(resolve, retryDelay))
            return fetchWithRetry(retryCount + 1)
          }

          if (error.message.toLowerCase().includes("prompt is too long")) {
            toast({
              title: 'Prompt Too Long',
              description: 'Please try removing some of the files that aren\'t needed and retry the request.',
              variant: 'destructive',
              duration: Infinity,
              className: 'whitespace-break-spaces',
            });
          } else {
            toast({
              title: 'Error',
              description: error.message,
              variant: 'destructive',
              duration: Infinity,
            });
          }

          posthog_capture('chat errored', {
            error: error.message,
            stack: error.stack,
            url: window.location.href,
            error_type: 'fetchWithRetry',
            endpoint: url,
          })

          throw error // Re-throw the updated error
        }
      }

      return fetchWithRetry(0)
    },
    [accessToken, repoName]
)
}